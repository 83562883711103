
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { HomeLink } from '@/components/HomeLink';
import { ReferralCard } from '@/components/dashboard/ReferralCard';
import { useToast } from '@/hooks/use-toast';
import { Loader2, Share2 } from 'lucide-react';
import { Alert, AlertDescription } from "@/components/ui/alert";

const Referrals = () => {
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const getProfile = async () => {
      try {
        setLoading(true);
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          toast({
            title: "Authentication required",
            description: "Please sign in to view your referrals",
            variant: "destructive",
          });
          navigate('/auth');
          return;
        }

        const { data, error } = await supabase
          .from('profiles')
          .select('referral_code, is_ambassador')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setProfile(data);
      } catch (error: any) {
        console.error('Error loading profile:', error.message);
        toast({
          title: "Error",
          description: "Failed to load referral information",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    getProfile();
  }, [navigate, toast]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate p-8 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin text-gameGold" />
          <p className="text-gameGold">Loading referrals...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate p-8">
      <HomeLink />
      <div className="max-w-2xl mx-auto mt-16">
        <div className="flex items-center gap-2 mb-8">
          <Share2 className="h-6 w-6 text-gameGold" />
          <h1 className="text-3xl font-bold text-gameGold">Referrals</h1>
        </div>
        
        <div className="space-y-8">
          <Alert>
            <AlertDescription className="text-gray-300">
              Share your unique referral link with friends. When they sign up using your link, 
              you'll earn ambassador points and get exclusive perks!
            </AlertDescription>
          </Alert>

          {profile && (
            <ReferralCard
              referralCode={profile.referral_code}
              isAmbassador={profile.is_ambassador}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Referrals;
