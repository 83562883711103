
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";

export const useAmbassadorStats = () => {
  const [referralCount, setReferralCount] = useState<number>(0);
  const [ambassadorRank, setAmbassadorRank] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  const fetchReferralCount = async (userId: string) => {
    try {
      console.log('Fetching referrals for user:', userId);

      const { count, error: countError } = await supabase
        .from('referrals')
        .select('*', { count: 'exact', head: true })
        .eq('referrer_id', userId);

      if (countError) {
        console.error('Error fetching referral count:', countError);
        throw countError;
      }

      console.log('Fetched referral count:', count);
      
      const currentCount = count || 0;
      setReferralCount(currentCount);
      
      if (currentCount >= 1) {
        setAmbassadorRank(`Ambassador Rank ${currentCount}`);
      } else {
        setAmbassadorRank(null);
      }

      return currentCount;
    } catch (error: any) {
      console.error('Error in fetchReferralCount:', error.message);
      throw error;
    }
  };

  useEffect(() => {
    const initializeStats = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw new Error('Authentication error. Please sign in again.');
        }
        
        if (!session) {
          console.log('No active session found');
          toast({
            title: "Session expired",
            description: "Please sign in again",
            variant: "destructive",
          });
          navigate('/auth');
          return;
        }

        // Initial fetch
        await fetchReferralCount(session.user.id);

        // Set up real-time subscription
        const channel = supabase
          .channel('referrals-changes')
          .on(
            'postgres_changes',
            {
              event: '*',
              schema: 'public',
              table: 'referrals',
              filter: `referrer_id=eq.${session.user.id}`
            },
            async (payload) => {
              console.log('Realtime referral update received:', payload);
              await fetchReferralCount(session.user.id);
              
              // Show toast notification for new referrals
              if (payload.eventType === 'INSERT') {
                toast({
                  title: "New Referral!",
                  description: "Someone just used your referral code!",
                  variant: "default",
                });
              }
            }
          )
          .subscribe((status) => {
            console.log('Realtime subscription status:', status);
          });

        return () => {
          supabase.removeChannel(channel);
        };

      } catch (error: any) {
        console.error('Error fetching ambassador stats:', error.message);
        setError(error.message);
        
        if (error.message.includes('Authentication') || error.message.includes('session')) {
          toast({
            title: "Authentication error",
            description: "Please sign in again",
            variant: "destructive",
          });
          navigate('/auth');
        }
      } finally {
        setIsLoading(false);
      }
    };

    initializeStats();
  }, [navigate, toast]);

  return {
    referralCount,
    ambassadorRank,
    isLoading,
    error
  };
};
