
import React, { useEffect, useState } from 'react';
import { supabase } from "@/integrations/supabase/client";

interface AdUnitProps {
  slot: string;
  format?: 'auto' | 'fluid' | 'rectangle' | 'horizontal' | 'vertical';
  className?: string;
}

interface AdSlot {
  slot_id: string;
}

export const AdUnit = ({ slot, format = 'auto', className = '' }: AdUnitProps) => {
  const [adSlot, setAdSlot] = useState<string | null>(null);

  useEffect(() => {
    const fetchAdSlot = async () => {
      const { data, error } = await supabase
        .from('ad_slots')
        .select('slot_id')
        .eq('location', slot)
        .maybeSingle();

      if (!error && data) {
        setAdSlot(data.slot_id);
      } else {
        console.log(`No ad slot found for location: ${slot}`);
      }
    };

    fetchAdSlot();
  }, [slot]);

  useEffect(() => {
    if (adSlot) {
      try {
        ((window as Window).adsbygoogle = (window as Window).adsbygoogle || []).push({});
      } catch (err) {
        console.error('Error loading ad:', err);
      }
    }
  }, [adSlot]);

  if (!adSlot) return null;

  return (
    <div className={`ad-container ${className}`}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-6089598110001081"
        data-ad-slot={adSlot}
        data-ad-format={format}
        data-full-width-responsive="true"
      />
    </div>
  );
};
