import { useState } from "react";
import { Copy, Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

interface ReferralLinkProps {
  referralCode: string;
}

export const ReferralLink = ({ referralCode }: ReferralLinkProps) => {
  const [copied, setCopied] = useState(false);
  const { toast } = useToast();
  
  // Remove https:// since it will be added by the browser and include mode=signup
  const referralLink = `gameofswordkings.com/auth?ref=${referralCode}&mode=signup`;

  const copyToClipboard = async () => {
    try {
      // Add https:// when copying to clipboard
      await navigator.clipboard.writeText(`https://${referralLink}`);
      setCopied(true);
      toast({
        title: "Link copied!",
        description: "The referral link has been copied to your clipboard.",
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast({
        title: "Failed to copy",
        description: "Please try copying the link manually.",
        variant: "destructive",
      });
    }
  };

  return (
    <Button
      onClick={copyToClipboard}
      variant="outline"
      size="sm"
      className="w-full bg-white/5 border-white/20 text-white hover:bg-white/10"
    >
      {copied ? (
        <Check className="h-4 w-4 mr-2" />
      ) : (
        <Copy className="h-4 w-4 mr-2" />
      )}
      Share Link
    </Button>
  );
};