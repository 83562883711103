
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { AmbassadorTable } from "./user/AmbassadorTable";

export const AmbassadorManagement = () => {
  const [ambassadors, setAmbassadors] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    fetchAmbassadors();
  }, []);

  const fetchAmbassadors = async () => {
    try {
      const { data, error } = await supabase
        .from('ambassador_stats')
        .select('*')
        .order('referral_count', { ascending: false });

      if (error) throw error;

      setAmbassadors(data || []);
    } catch (error: any) {
      console.error("Error fetching ambassadors:", error);
      toast({
        title: "Error",
        description: "Failed to fetch ambassador data",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredAmbassadors = ambassadors.filter((ambassador) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      ambassador.display_name?.toLowerCase().includes(searchLower) ||
      ambassador.username?.toLowerCase().includes(searchLower) ||
      ambassador.referral_code?.toLowerCase().includes(searchLower)
    );
  });

  if (loading) {
    return <p>Loading ambassador data...</p>;
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          type="text"
          placeholder="Search ambassadors by name, username, or referral code..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10"
        />
      </div>
      <div className="rounded-md border">
        <AmbassadorTable ambassadors={filteredAmbassadors} />
      </div>
    </div>
  );
};
