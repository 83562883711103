
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader2, Trash2, Pencil } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface AdSlot {
  id: string;
  location: string;
  slot_id: string;
  description: string | null;
}

export const AdSlotManager = () => {
  const [adSlots, setAdSlots] = useState<AdSlot[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingSlot, setEditingSlot] = useState<AdSlot | null>(null);
  const [newSlot, setNewSlot] = useState({
    location: "",
    slot_id: "",
    description: "",
  });
  const { toast } = useToast();

  useEffect(() => {
    fetchAdSlots();
  }, []);

  const fetchAdSlots = async () => {
    const { data, error } = await supabase
      .from("ad_slots")
      .select("*")
      .order("created_at", { ascending: true });

    if (error) {
      console.error("Error fetching ad slots:", error);
      toast({
        title: "Error",
        description: "Failed to fetch ad slots",
        variant: "destructive",
      });
      return;
    }

    setAdSlots(data);
    setLoading(false);
  };

  const handleAddSlot = async () => {
    if (!newSlot.location || !newSlot.slot_id) {
      toast({
        title: "Error",
        description: "Location and Slot ID are required",
        variant: "destructive",
      });
      return;
    }

    const { error } = await supabase.from("ad_slots").insert([newSlot]);

    if (error) {
      console.error("Error adding ad slot:", error);
      toast({
        title: "Error",
        description: "Failed to add ad slot",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Success",
      description: "Ad slot added successfully",
    });

    setNewSlot({
      location: "",
      slot_id: "",
      description: "",
    });
    fetchAdSlots();
  };

  const handleStartEdit = (slot: AdSlot) => {
    setEditingSlot(slot);
  };

  const handleCancelEdit = () => {
    setEditingSlot(null);
  };

  const handleUpdateSlot = async () => {
    if (!editingSlot) return;

    if (!editingSlot.location || !editingSlot.slot_id) {
      toast({
        title: "Error",
        description: "Location and Slot ID are required",
        variant: "destructive",
      });
      return;
    }

    const { error } = await supabase
      .from("ad_slots")
      .update({
        location: editingSlot.location,
        slot_id: editingSlot.slot_id,
        description: editingSlot.description,
      })
      .eq("id", editingSlot.id);

    if (error) {
      console.error("Error updating ad slot:", error);
      toast({
        title: "Error",
        description: "Failed to update ad slot",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Success",
      description: "Ad slot updated successfully",
    });

    setEditingSlot(null);
    fetchAdSlots();
  };

  const handleDeleteSlot = async (id: string) => {
    const { error } = await supabase.from("ad_slots").delete().eq("id", id);

    if (error) {
      console.error("Error deleting ad slot:", error);
      toast({
        title: "Error",
        description: "Failed to delete ad slot",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Success",
      description: "Ad slot deleted successfully",
    });
    fetchAdSlots();
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid gap-4">
        <h2 className="text-2xl font-bold">Ad Slot Management</h2>
        
        <Alert>
          <AlertDescription>
            Available ad locations: <code>top-banner</code>, <code>hero-feature</code>, <code>pre-footer</code>, <code>game-content</code>
          </AlertDescription>
        </Alert>

        <div className="grid gap-4 md:grid-cols-4">
          <Input
            placeholder="Location (e.g., top-banner)"
            value={newSlot.location}
            onChange={(e) =>
              setNewSlot({ ...newSlot, location: e.target.value })
            }
          />
          <Input
            placeholder="AdSense Slot ID"
            value={newSlot.slot_id}
            onChange={(e) =>
              setNewSlot({ ...newSlot, slot_id: e.target.value })
            }
          />
          <Input
            placeholder="Description (optional)"
            value={newSlot.description || ""}
            onChange={(e) =>
              setNewSlot({ ...newSlot, description: e.target.value })
            }
          />
          <Button onClick={handleAddSlot}>Add Ad Slot</Button>
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Location</TableHead>
            <TableHead>Slot ID</TableHead>
            <TableHead>Description</TableHead>
            <TableHead className="w-[180px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {adSlots.map((slot) => (
            <TableRow key={slot.id}>
              <TableCell>
                {editingSlot?.id === slot.id ? (
                  <Input
                    value={editingSlot.location}
                    onChange={(e) =>
                      setEditingSlot({ ...editingSlot, location: e.target.value })
                    }
                  />
                ) : (
                  slot.location
                )}
              </TableCell>
              <TableCell>
                {editingSlot?.id === slot.id ? (
                  <Input
                    value={editingSlot.slot_id}
                    onChange={(e) =>
                      setEditingSlot({ ...editingSlot, slot_id: e.target.value })
                    }
                  />
                ) : (
                  slot.slot_id
                )}
              </TableCell>
              <TableCell>
                {editingSlot?.id === slot.id ? (
                  <Input
                    value={editingSlot.description || ""}
                    onChange={(e) =>
                      setEditingSlot({ ...editingSlot, description: e.target.value })
                    }
                  />
                ) : (
                  slot.description
                )}
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  {editingSlot?.id === slot.id ? (
                    <>
                      <Button onClick={handleUpdateSlot} size="sm">
                        Save
                      </Button>
                      <Button 
                        onClick={handleCancelEdit}
                        variant="outline"
                        size="sm"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleStartEdit(slot)}
                      >
                        <Pencil className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => handleDeleteSlot(slot.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
          {adSlots.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                No ad slots configured
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

