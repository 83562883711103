
import { supabase } from "@/integrations/supabase/client";
import { Question } from "@/types/quiz";
import { useToast } from "@/components/ui/use-toast";
import { getExistingResponse, updateQuizResponse, createQuizResponse } from "@/utils/quiz-responses";

export const useQuizResponse = () => {
  const { toast } = useToast();

  const handleQuizResponse = async (
    quizId: string,
    userId: string,
    questions: Question[],
    currentQuestionIndex: number,
    selectedAnswer: string | null,
    finalScore: number
  ) => {
    try {
      // Get existing response
      const existingResponse = await getExistingResponse(quizId, userId);
      
      if (existingResponse) {
        console.log('Found existing response:', existingResponse);
        
        // If user already achieved a perfect score, no need to update
        if (existingResponse.perfect_score) {
          console.log("Perfect score already achieved, no update needed");
          toast({
            title: "Quiz Already Completed",
            description: "You've already achieved a perfect score on this quiz!",
          });
          return existingResponse.score;
        }

        // Only update if we have a perfect score now
        if (finalScore === questions.length) {
          console.log("Updating response with perfect score");
          const { error } = await updateQuizResponse(
            existingResponse.id,
            questions,
            currentQuestionIndex,
            selectedAnswer,
            finalScore
          );

          if (error) {
            console.error("Error updating quiz response:", error);
            toast({
              title: "Error",
              description: "Failed to save quiz response",
              variant: "destructive",
            });
            return null;
          }
        } else {
          console.log("Score not perfect, keeping existing response");
          toast({
            title: "Quiz Score",
            description: "You need a perfect score to complete this quiz!",
          });
          return existingResponse.score;
        }
      } else {
        // Only create a new response if the score is perfect
        if (finalScore === questions.length) {
          console.log("Creating new response with perfect score");
          const { error } = await createQuizResponse(
            quizId,
            userId,
            questions,
            currentQuestionIndex,
            selectedAnswer,
            finalScore
          );

          if (error) {
            console.error("Error creating quiz response:", error);
            toast({
              title: "Error",
              description: "Failed to save quiz response",
              variant: "destructive",
            });
            return null;
          }
        } else {
          console.log("Score not perfect, no response created");
          toast({
            title: "Quiz Score",
            description: "You need a perfect score to complete this quiz!",
          });
          return 0;
        }
      }

      return finalScore;
    } catch (error) {
      console.error("Error handling quiz response:", error);
      toast({
        title: "Error",
        description: "An unexpected error occurred",
        variant: "destructive",
      });
      return null;
    }
  };

  return { handleQuizResponse };
};
