
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { supabase } from "@/integrations/supabase/client";
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { useQuery } from '@tanstack/react-query';

export const HomeNavigation = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  // Get session data
  const { data: session } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      return session;
    },
  });

  // Get profile data including admin status and points
  const { data: profile } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user?.id,
  });

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Error signing out:", error);
        toast({
          title: "Error",
          description: "Failed to sign out. Please try again.",
          variant: "destructive",
        });
        return;
      }
      
      setIsMenuOpen(false);
      navigate('/auth');
      toast({
        title: "Success",
        description: "Signed out successfully",
      });
    } catch (error) {
      console.error("Error in logout process:", error);
      // Force navigate to auth page even if there's an error
      navigate('/auth');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="container mx-auto px-4 py-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <img
            src="/lovable-uploads/f77ed799-492e-4d30-a56c-591a8e002821.png"
            alt="Game Logo"
            className="w-12 h-12"
          />
          <h1 className="text-gameGold text-xl md:text-2xl font-bold">Game of Sword Kings</h1>
        </div>

        <DesktopNav 
          user={session?.user}
          userPoints={profile?.points || 0}
          isAdmin={!!profile?.is_admin}
          handleSignOut={handleSignOut}
        />

        <MobileNav 
          user={session?.user}
          userPoints={profile?.points || 0}
          isAdmin={!!profile?.is_admin}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          closeMenu={closeMenu}
          handleSignOut={handleSignOut}
        />
      </div>
    </nav>
  );
};
