
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { MultiplayerParticipant } from '@/types/multiplayer-room';

export const useParticipants = (roomId: string | undefined) => {
  const { toast } = useToast();
  const [participants, setParticipants] = useState<MultiplayerParticipant[]>([]);
  const [isReady, setIsReady] = useState(false);

  const setPlayerReady = async () => {
    try {
      const { data: user } = await supabase.auth.getUser();
      if (!user.user) {
        throw new Error('User not authenticated');
      }

      const { error } = await supabase
        .from('multiplayer_participants')
        .update({ is_ready: true })
        .eq('room_id', roomId)
        .eq('user_id', user.user.id);

      if (error) throw error;

      setIsReady(true);
      console.log('Player ready status updated successfully');
    } catch (error) {
      console.error('Error updating ready status:', error);
      toast({
        title: "Error",
        description: "Failed to update ready status. Please try again.",
        variant: "destructive",
      });
    }
  };

  const leaveGame = async () => {
    try {
      const { data: user } = await supabase.auth.getUser();
      if (!user.user) {
        throw new Error('User not authenticated');
      }

      const { error } = await supabase
        .from('multiplayer_participants')
        .delete()
        .eq('room_id', roomId)
        .eq('user_id', user.user.id);

      if (error) throw error;

      toast({
        title: "Game Exited",
        description: "You have left the game",
      });
      console.log('Successfully left the game');
    } catch (error) {
      console.error('Error leaving game:', error);
      toast({
        title: "Error",
        description: "Failed to leave game. Please try again.",
        variant: "destructive",
      });
    }
  };

  return {
    participants,
    setParticipants,
    isReady,
    setPlayerReady,
    leaveGame
  };
};
