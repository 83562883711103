import { supabase } from '@/integrations/supabase/client';

export const cleanupStaleParticipation = async (userId: string) => {
  console.log('Cleaning up stale participations for user:', userId);
  
  try {
    // Get all rooms that are completed or have been inactive for more than 30 minutes
    const thirtyMinutesAgo = new Date();
    thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30);
    
    // First, find stale rooms for this user
    const { data: staleRooms, error: queryError } = await supabase
      .from('multiplayer_rooms')
      .select('id')
      .or(`status.eq.completed,created_at.lt.${thirtyMinutesAgo.toISOString()}`);
    
    if (queryError) {
      console.error('Error finding stale rooms:', queryError);
      return;
    }
    
    if (staleRooms && staleRooms.length > 0) {
      const staleRoomIds = staleRooms.map(room => room.id);
      
      // Delete the user's participation in stale rooms
      const { error: deleteError } = await supabase
        .from('multiplayer_participants')
        .delete()
        .eq('user_id', userId)
        .in('room_id', staleRoomIds);
      
      if (deleteError) {
        console.error('Error deleting stale participations:', deleteError);
      } else {
        console.log(`Cleaned up ${staleRoomIds.length} stale participations for user ${userId}`);
      }
    }
    
    // Also clean up any in_progress rooms where the user might be stuck
    const { data: inProgressParticipations, error: inProgressError } = await supabase
      .from('multiplayer_participants')
      .select('room_id, multiplayer_rooms!inner(status)')
      .eq('user_id', userId)
      .eq('multiplayer_rooms.status', 'in_progress');
      
    if (inProgressError) {
      console.error('Error finding in-progress participations:', inProgressError);
      return;
    }
    
    if (inProgressParticipations && inProgressParticipations.length > 0) {
      console.log('Found in-progress participations that need cleanup:', inProgressParticipations);
      
      // For rooms older than 2 hours, assume they're stale and remove user
      const twoHoursAgo = new Date();
      twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);
      
      // First get the room creation times to check age
      const roomIds = inProgressParticipations.map(p => p.room_id);
      const { data: roomsData } = await supabase
        .from('multiplayer_rooms')
        .select('id, created_at')
        .in('id', roomIds);
      
      if (roomsData) {
        const staleRoomIds = roomsData
          .filter(room => new Date(room.created_at) < twoHoursAgo)
          .map(room => room.id);
          
        if (staleRoomIds.length > 0) {
          const { error: cleanupError } = await supabase
            .from('multiplayer_participants')
            .delete()
            .eq('user_id', userId)
            .in('room_id', staleRoomIds);
            
          if (cleanupError) {
            console.error('Error cleaning up stale in-progress participations:', cleanupError);
          } else {
            console.log(`Cleaned up ${staleRoomIds.length} stale in-progress participations`);
          }
        }
      }
    }
  } catch (error) {
    console.error('Error in cleanupStaleParticipation:', error);
  }
};

export const leaveRoom = async (roomId: string, userId: string) => {
  try {
    // First check if the user is part of this room
    const { data: participant, error: checkError } = await supabase
      .from('multiplayer_participants')
      .select('id')
      .eq('room_id', roomId)
      .eq('user_id', userId)
      .single();
      
    if (checkError) {
      console.error('Error checking if user is in room:', checkError);
      throw new Error('Failed to verify room participation');
    }
    
    if (!participant) {
      throw new Error('You are not part of this room');
    }
    
    // Delete the participation
    const { error: deleteError } = await supabase
      .from('multiplayer_participants')
      .delete()
      .eq('room_id', roomId)
      .eq('user_id', userId);
      
    if (deleteError) {
      console.error('Error leaving room:', deleteError);
      throw new Error('Failed to leave room');
    }
    
    // Check if room is now empty
    const { data: remainingParticipants, error: countError } = await supabase
      .from('multiplayer_participants')
      .select('id')
      .eq('room_id', roomId);
      
    if (countError) {
      console.error('Error counting participants:', countError);
      return;
    }
    
    // If room is empty, mark it as completed
    if (!remainingParticipants || remainingParticipants.length === 0) {
      const { error: updateError } = await supabase
        .from('multiplayer_rooms')
        .update({ status: 'completed', completed_at: new Date().toISOString() })
        .eq('id', roomId);
        
      if (updateError) {
        console.error('Error updating empty room status:', updateError);
      }
    }
    
    return true;
  } catch (error) {
    console.error('Error in leaveRoom:', error);
    throw error;
  }
};

export const joinExistingRoom = async (roomId: string, userId: string) => {
  try {
    console.log('Joining existing room:', roomId, 'user:', userId);
    
    // First ensure any stale participations are cleaned up
    await cleanupStaleParticipation(userId);
    
    // Check if the room exists and is in waiting status
    const { data: room, error: roomError } = await supabase
      .from('multiplayer_rooms')
      .select('*')
      .eq('id', roomId)
      .eq('status', 'waiting')
      .single();
      
    if (roomError) {
      console.error('Error checking room status:', roomError);
      throw new Error('Room not found or no longer accepting players');
    }
    
    // Check if the room is already full
    const { data: participants, error: participantsError } = await supabase
      .from('multiplayer_participants')
      .select('count')
      .eq('room_id', roomId);
      
    if (participantsError) {
      console.error('Error checking participants count:', participantsError);
      throw new Error('Could not verify room capacity');
    }
    
    // Check if user is already in another active room
    const { data: existingParticipation, error: participationError } = await supabase
      .from('multiplayer_participants')
      .select('room_id, multiplayer_rooms!inner(status)')
      .eq('user_id', userId)
      .neq('multiplayer_rooms.status', 'completed');
      
    if (participationError) {
      console.error('Error checking existing participation:', participationError);
      throw new Error('Could not verify current participation status');
    }
    
    if (existingParticipation && existingParticipation.length > 0) {
      // Get IDs of rooms user is participating in
      const roomIds = existingParticipation.map(p => p.room_id);
      
      // Force cleanup of any waiting rooms
      const { data: waitingRooms } = await supabase
        .from('multiplayer_rooms')
        .select('id')
        .in('id', roomIds)
        .eq('status', 'waiting');
        
      if (waitingRooms && waitingRooms.length > 0) {
        const waitingRoomIds = waitingRooms.map(r => r.id);
        
        // Remove user from waiting rooms
        const { error: cleanupError } = await supabase
          .from('multiplayer_participants')
          .delete()
          .eq('user_id', userId)
          .in('room_id', waitingRoomIds);
          
        if (cleanupError) {
          console.error('Error cleaning up waiting rooms:', cleanupError);
          throw new Error('Error cleaning up previous room participation');
        }
        
        console.log(`Cleaned up user participation in ${waitingRoomIds.length} waiting rooms`);
      } else {
        // If user is in in-progress rooms, don't allow joining another
        throw new Error('You are already in another room. Please leave that room first.');
      }
    }
    
    // Add user as participant
    const { error: joinError } = await supabase
      .from('multiplayer_participants')
      .insert([
        {
          room_id: roomId,
          user_id: userId,
          score: 0,
          is_ready: false
        }
      ]);
      
    if (joinError) {
      console.error('Error joining room:', joinError);
      throw new Error('Failed to join room');
    }
    
    return room;
  } catch (error) {
    console.error('Error in joinExistingRoom:', error);
    throw error;
  }
};
