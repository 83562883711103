
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

interface SiteRating {
  id: string;
  rating: number;
  comment: string | null;
  user_id: string;
  created_at: string;
  is_approved: boolean;
  profiles: {
    username: string | null;
    display_name: string;
  } | null;
}

export const RatingManagement = () => {
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: ratings, refetch } = useQuery({
    queryKey: ["site-ratings"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("site_ratings")
        .select(`
          *,
          profiles:user_id (
            username,
            display_name
          )
        `)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching ratings:', error);
        throw error;
      }
      return data as SiteRating[];
    },
  });

  const handleApproval = async (ratingId: string, approve: boolean) => {
    setIsProcessing(true);
    try {
      const { error } = await supabase
        .from('site_ratings')
        .update({ is_approved: approve })
        .eq('id', ratingId);

      if (error) throw error;

      toast({
        title: "Success",
        description: `Rating ${approve ? 'approved' : 'rejected'} successfully`,
      });

      refetch();
    } catch (error) {
      console.error('Error updating rating:', error);
      toast({
        title: "Error",
        description: "Failed to update rating status",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Site Ratings Management</h2>
      <div className="space-y-4">
        {ratings?.map((rating) => (
          <div
            key={rating.id}
            className="p-4 bg-white/5 rounded-lg space-y-2"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-400">
                  By {rating.profiles?.display_name || 'Unknown'}
                  {rating.profiles?.username && ` (@${rating.profiles.username})`}
                </p>
                <div className="flex items-center gap-2">
                  <span className="text-lg font-semibold">{rating.rating}/5</span>
                  <span className="text-sm text-gray-400">
                    {new Date(rating.created_at).toLocaleDateString()}
                  </span>
                </div>
                {rating.comment && (
                  <p className="mt-2 text-gray-200">{rating.comment}</p>
                )}
              </div>
              <div className="flex gap-2">
                {!rating.is_approved ? (
                  <>
                    <Button
                      onClick={() => handleApproval(rating.id, true)}
                      disabled={isProcessing}
                      variant="default"
                      className="bg-green-600 hover:bg-green-700"
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() => handleApproval(rating.id, false)}
                      disabled={isProcessing}
                      variant="destructive"
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <span className="text-green-500">Approved</span>
                )}
              </div>
            </div>
          </div>
        ))}
        {!ratings?.length && (
          <p className="text-center text-gray-400">No ratings to display</p>
        )}
      </div>
    </div>
  );
};
