
import { Link } from 'react-router-dom';
import { RatingStars } from './rating/RatingStars';
import { RatingForm } from './rating/RatingForm';
import { useRating } from './rating/useRating';

export const SiteRating = () => {
  const {
    user,
    hoveredRating,
    setHoveredRating,
    stats,
    approvedRatings,
    comment,
    setComment,
    isSubmitting,
    selectedRating,
    setSelectedRating,
    handleSubmit,
  } = useRating();

  const handleRatingClick = (rating: number) => {
    if (!user) {
      return;
    }
    setSelectedRating(rating);
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex flex-col items-center">
        <div className="text-sm text-gray-300 mb-2">
          Site Rating: {stats.averageRating}/5 ({stats.totalRatings} ratings)
        </div>
        <RatingStars
          rating={selectedRating}
          hoveredRating={hoveredRating}
          selectedRating={selectedRating}
          isSubmitting={isSubmitting}
          onHover={setHoveredRating}
          onClick={handleRatingClick}
        />
      </div>
      
      {user ? (
        <RatingForm
          comment={comment}
          isSubmitting={isSubmitting}
          onCommentChange={setComment}
          onSubmit={handleSubmit}
        />
      ) : (
        <div className="text-center">
          <Link 
            to="/auth" 
            className="text-gameGold hover:text-gameGold/90 underline"
          >
            Sign in to rate
          </Link>
        </div>
      )}

      {approvedRatings.length > 0 && (
        <div className="w-full max-w-md mt-4">
          <h4 className="text-gameGold text-sm font-semibold mb-3">Recent Reviews</h4>
          <div className="space-y-4">
            {approvedRatings.map((rating, index) => (
              <div key={index} className="bg-white/5 rounded-lg p-3">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-300 text-sm">
                    {rating.profiles?.display_name || 'Anonymous'}
                  </span>
                  <span className="text-gameGold">{rating.rating}/5</span>
                </div>
                {rating.comment && (
                  <p className="text-gray-300 text-sm">{rating.comment}</p>
                )}
                <div className="text-gray-400 text-xs mt-2">
                  {new Date(rating.created_at).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
