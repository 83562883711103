
import { LeaderboardHeader } from "@/components/leaderboard/LeaderboardHeader";
import { HomeLink } from "@/components/HomeLink";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useLeaderboard } from "@/hooks/use-leaderboard";
import { LeaderboardEntry } from "@/components/leaderboard/LeaderboardEntry";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Award, Trophy, Swords } from "lucide-react";

const Leaderboards = () => {
  const { leaderboardData: globalLeaderboard, isLoading: isGlobalLoading } = useLeaderboard();

  const { data: ambassadorLeaderboard, isLoading: isAmbassadorLoading } = useQuery({
    queryKey: ["ambassador-leaderboard"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('ambassador_leaderboard')
        .select('*')
        .limit(10);
      
      if (error) throw error;
      return data.map((entry, index) => ({
        rank: index + 1,
        name: entry.display_name || entry.username || 'Anonymous',
        score: entry.referral_count,
        country: entry.country || 'Unknown'
      }));
    }
  });

  const { data: multiplayerLeaderboard, isLoading: isMultiplayerLoading } = useQuery({
    queryKey: ["multiplayer-leaderboard"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('multiplayer_leaderboard')
        .select(`
          *,
          profiles:user_id (
            display_name,
            username,
            country
          )
        `)
        .order('total_points', { ascending: false })
        .order('wins', { ascending: false })
        .limit(10);
      
      if (error) throw error;
      return data;
    }
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate p-4 md:p-8">
      <HomeLink />
      <div className="max-w-4xl mx-auto mt-8">
        <h1 className="text-4xl font-bold text-gameGold text-center mb-8">Leaderboards</h1>
        
        <Tabs defaultValue="global" className="w-full">
          <TabsList className="grid grid-cols-3 w-full">
            <TabsTrigger value="global" className="flex items-center gap-2">
              <Trophy className="w-4 h-4" />
              <span className="hidden sm:inline">Global</span>
            </TabsTrigger>
            <TabsTrigger value="ambassador" className="flex items-center gap-2">
              <Award className="w-4 h-4" />
              <span className="hidden sm:inline">Ambassadors</span>
            </TabsTrigger>
            <TabsTrigger value="multiplayer" className="flex items-center gap-2">
              <Swords className="w-4 h-4" />
              <span className="hidden sm:inline">Multiplayer</span>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="global" className="mt-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gameGold mb-4">Global Rankings</h2>
              {isGlobalLoading ? (
                <p className="text-center text-gray-400">Loading global rankings...</p>
              ) : (
                globalLeaderboard?.map((entry) => (
                  <LeaderboardEntry key={entry.rank} {...entry} />
                ))
              )}
            </div>
          </TabsContent>

          <TabsContent value="ambassador" className="mt-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gameGold mb-4">Top Ambassadors</h2>
              {isAmbassadorLoading ? (
                <p className="text-center text-gray-400">Loading ambassador rankings...</p>
              ) : (
                ambassadorLeaderboard?.map((entry) => (
                  <LeaderboardEntry key={entry.rank} {...entry} />
                ))
              )}
            </div>
          </TabsContent>

          <TabsContent value="multiplayer" className="mt-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gameGold mb-4">Multiplayer Champions</h2>
              {isMultiplayerLoading ? (
                <p className="text-center text-gray-400">Loading multiplayer rankings...</p>
              ) : (
                <div className="bg-gamePurple/30 rounded-lg overflow-hidden">
                  <div className="grid grid-cols-4 gap-4 p-4 border-b border-gameGold/20 font-bold text-gameGold">
                    <div>Player</div>
                    <div>Wins</div>
                    <div>Losses</div>
                    <div>Points</div>
                  </div>
                  {multiplayerLeaderboard?.map((entry) => (
                    <div key={entry.id} className="grid grid-cols-4 gap-4 p-4 border-b border-gameGold/10">
                      <div className="text-gameGold">
                        {entry.profiles?.display_name || entry.profiles?.username || 'Unknown Player'}
                      </div>
                      <div className="text-green-500">{entry.wins}</div>
                      <div className="text-red-500">{entry.losses}</div>
                      <div className="text-gameGold">{entry.total_points}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Leaderboards;
