
import { supabase } from '@/integrations/supabase/client';
import { QuizWebSocketState, PresenceData } from './websocket/types';
import { getState, setState, removeState, getAllStates, updateConnectionStatus } from './websocket/state-manager';
import { logError, logMetrics } from './websocket/metrics-manager';
import { setupPresenceHandlers } from './websocket/presence-handler';

export const HEARTBEAT_INTERVAL = 5000; // 5 seconds

export const initializeQuizWebSocket = (roomId: string) => {
  console.log(`Initializing WebSocket for room: ${roomId}`);
  
  const existingState = getState(roomId);
  if (existingState?.channel) {
    console.log(`Cleaning up existing channel for room: ${roomId}`);
    existingState.channel.unsubscribe();
  }

  const connectionStartTime = Date.now();
  const state: QuizWebSocketState = {
    channel: null,
    lastPing: Date.now(),
    connectionStatus: 'disconnected',
    metrics: {
      latency: [],
      messageCount: 0,
      reconnectAttempts: 0,
      lastReconnectTime: connectionStartTime,
      errors: [],
    },
  };

  const channel = supabase.channel(`realtime:${roomId}`, {
    config: {
      broadcast: { self: true },
      presence: {
        key: roomId,
      },
    },
  });

  // Set up presence handlers
  setupPresenceHandlers(channel, roomId, connectionStartTime);

  // Store the channel in state before subscribing
  state.channel = channel;
  setState(roomId, state);

  // Subscribe and track presence
  channel.subscribe(async (status) => {
    console.log(`WebSocket status updated for room ${roomId}:`, status);
    const currentState = getState(roomId);
    
    if (!currentState) return;

    if (status === 'SUBSCRIBED') {
      currentState.connectionStatus = 'connected';
      // Track presence after successful subscription
      await channel.track({
        room_id: roomId,
        online_at: new Date().toISOString(),
      } as PresenceData);
    } else if (status === 'CHANNEL_ERROR') {
      logError(roomId, 'Channel subscription error');
      currentState.connectionStatus = 'disconnected';
    } else if (status === 'CLOSED') {
      currentState.connectionStatus = 'disconnected';
    }
    
    logMetrics(roomId);
  });

  return channel;
};

export const heartbeat = async (roomId: string) => {
  const state = getState(roomId);
  if (!state || !state.channel || state.connectionStatus !== 'connected') {
    logError(roomId, 'Connection lost, attempting reconnect');
    state && updateConnectionStatus(roomId, 'reconnecting');
    await initializeQuizWebSocket(roomId);
    return;
  }

  const now = Date.now();
  if (now - state.lastPing > HEARTBEAT_INTERVAL * 2) {
    logError(roomId, 'Connection stale, attempting reconnect');
    updateConnectionStatus(roomId, 'reconnecting');
    await initializeQuizWebSocket(roomId);
  }
};

export const getMetrics = () => {
  const allStates = getAllStates();
  console.log('Active channels:', allStates.length);
  
  const allMetrics = allStates.map(([roomId, state]) => ({
    roomId,
    connectionStatus: state.connectionStatus,
    metrics: { ...state.metrics },
    lastPing: state.lastPing,
  }));

  if (allMetrics.length === 0) {
    console.log('No active channels found, returning disconnected state');
    return {
      connectionStatus: 'disconnected',
      metrics: {
        latency: [],
        messageCount: 0,
        reconnectAttempts: 0,
        lastReconnectTime: null,
        errors: [],
      },
      lastPing: 0,
    };
  }

  const sortedMetrics = allMetrics.sort((a, b) => b.lastPing - a.lastPing);
  console.log('Most recent channel metrics:', sortedMetrics[0]);
  return sortedMetrics[0];
};

export const getConnectionStatus = () => {
  const states = getAllStates();
  return states.some(([_, state]) => state.connectionStatus === 'connected') ? 'connected' : 'disconnected';
};

export const cleanup = (roomId?: string) => {
  if (roomId) {
    const state = getState(roomId);
    if (state?.channel) {
      state.channel.unsubscribe();
    }
    removeState(roomId);
  } else {
    // Cleanup all channels
    const states = getAllStates();
    states.forEach(([_, state]) => {
      if (state.channel) {
        state.channel.unsubscribe();
      }
    });
    // Clear the Map through the state manager
    states.forEach(([roomId]) => removeState(roomId));
  }
};
