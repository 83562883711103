import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { countries } from "@/constants/countries";

interface FormFieldsProps {
  username: string;
  setUsername: (value: string) => void;
  displayName: string;
  setDisplayName: (value: string) => void;
  country: string;
  setCountry: (value: string) => void;
}

export const FormFields = ({
  username,
  setUsername,
  displayName,
  setDisplayName,
  country,
  setCountry,
}: FormFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <label htmlFor="username" className="text-sm font-medium text-gray-200">
          Username
        </label>
        <Input
          id="username"
          value={username}
          onChange={(e) => {
            console.log("Username changed:", e.target.value);
            setUsername(e.target.value);
          }}
          className="bg-white/20 border-white/30 text-white placeholder:text-gray-400"
          required
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="displayName" className="text-sm font-medium text-gray-200">
          Display Name
        </label>
        <Input
          id="displayName"
          value={displayName}
          onChange={(e) => {
            console.log("Display name changed:", e.target.value);
            setDisplayName(e.target.value);
          }}
          className="bg-white/20 border-white/30 text-white placeholder:text-gray-400"
          required
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="country" className="text-sm font-medium text-gray-200">
          Country
        </label>
        <Select 
          value={country} 
          onValueChange={(value) => {
            console.log("Country changed:", value);
            setCountry(value);
          }}
        >
          <SelectTrigger className="bg-white/20 border-white/30 text-white">
            <SelectValue placeholder="Select your country" />
          </SelectTrigger>
          <SelectContent className="max-h-[200px] overflow-y-auto bg-gamePurple border-white/30">
            {countries.map((country) => (
              <SelectItem
                key={country}
                value={country}
                className="text-white hover:bg-white/20"
              >
                {country}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
};