
import { useState, useCallback } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { useNavigate } from 'react-router-dom';
import { MultiplayerParticipant } from '@/types/multiplayer-room';

export const useGameState = (roomId: string | undefined) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

  const submitAnswer = useCallback(async (participants: MultiplayerParticipant[], quizData: any) => {
    if (!roomId) return;
    
    try {
      if (!selectedAnswer || !quizData?.questions?.[currentQuestion]) {
        throw new Error('No answer selected or invalid question');
      }

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('User not authenticated');
      }

      const isCorrect = selectedAnswer === quizData.questions[currentQuestion].correct_answer;
      const points = isCorrect ? 10 : 0;
      const currentParticipant = participants.find(p => p.user_id === user.id);
      
      if (!currentParticipant) {
        throw new Error('Participant not found');
      }

      const { error } = await supabase
        .from('multiplayer_participants')
        .update({
          score: (currentParticipant.score || 0) + points
        })
        .eq('room_id', roomId)
        .eq('user_id', user.id);

      if (error) throw error;

      setSelectedAnswer(null);
      setCurrentQuestion(prev => prev + 1);
      
      if (isCorrect) {
        toast({
          title: "Correct!",
          description: `You earned ${points} points`,
        });
      } else {
        toast({
          title: "Incorrect",
          description: "Better luck on the next question!",
        });
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
      toast({
        title: "Error",
        description: "Failed to submit answer. Please try again.",
        variant: "destructive",
      });
    }
  }, [roomId, currentQuestion, selectedAnswer, toast]);

  const handleGameEnd = useCallback(async (participants: MultiplayerParticipant[]) => {
    if (!roomId) return;
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('User not authenticated');
      }

      const currentParticipant = participants.find(p => p.user_id === user.id);
      if (!currentParticipant) {
        throw new Error('Participant not found');
      }

      // Update room status to completed
      const { error: roomError } = await supabase
        .from('multiplayer_rooms')
        .update({
          status: 'completed',
          completed_at: new Date().toISOString()
        })
        .eq('id', roomId);

      if (roomError) throw roomError;

      // Calculate winner and update stats
      const maxScore = Math.max(...participants.map(p => p.score || 0));
      const winners = participants.filter(p => p.score === maxScore);

      if (winners.length === 1) {
        const isWinner = winners[0].user_id === user.id;
        const pointsEarned = isWinner ? maxScore : 0;

        const { error } = await supabase.rpc('update_multiplayer_stats', {
          player_id: user.id,
          is_winner: isWinner,
          points_earned: pointsEarned
        });

        if (error) throw error;

        toast({
          title: isWinner ? "Congratulations!" : "Game Over",
          description: isWinner ? `You won with ${maxScore} points!` : "Better luck next time!",
        });
      } else {
        toast({
          title: "Game Over",
          description: "It's a tie!",
        });
      }

      navigate('/multiplayer');
    } catch (error) {
      console.error('Error handling game end:', error);
      toast({
        title: "Error",
        description: "Failed to process game end. Please try again.",
        variant: "destructive",
      });
      navigate('/multiplayer');
    }
  }, [roomId, navigate, toast]);

  return {
    currentQuestion,
    selectedAnswer,
    setSelectedAnswer,
    submitAnswer,
    handleGameEnd
  };
};
