
import { Quiz } from "@/types/quiz";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { QuizCard } from "@/components/quiz/QuizCard";

interface QuizListProps {
  quizzes: Quiz[] | undefined;
  isLoading: boolean;
  error: Error | null;
  onEdit: (quiz: Quiz) => void;
  onDelete: (quiz: Quiz) => void;
  onCreateNew: () => void;
}

export const QuizList = ({
  quizzes,
  isLoading,
  error,
  onEdit,
  onDelete,
  onCreateNew,
}: QuizListProps) => {
  const groupQuizzesByLevel = (quizzes: Quiz[] | undefined) => {
    if (!quizzes) return new Map<number, Quiz[]>();
    
    const groupedQuizzes = new Map<number, Quiz[]>();
    
    quizzes.forEach(quiz => {
      const level = quiz.questions?.[0]?.level || 1;
      
      if (!groupedQuizzes.has(level)) {
        groupedQuizzes.set(level, []);
      }
      
      groupedQuizzes.get(level)?.push(quiz);
    });
    
    return new Map([...groupedQuizzes.entries()].sort((a, b) => a[0] - b[0]));
  };

  const groupedQuizzes = groupQuizzesByLevel(quizzes);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Quizzes</h2>
        <Button onClick={onCreateNew}>Create Quiz</Button>
      </div>

      {isLoading && (
        <Alert>
          <AlertDescription>Loading quizzes...</AlertDescription>
        </Alert>
      )}

      {error && (
        <Alert variant="destructive">
          <AlertDescription>Failed to fetch quizzes. Please try again.</AlertDescription>
        </Alert>
      )}

      {!quizzes?.length && !isLoading && !error && (
        <Alert>
          <AlertDescription>No quizzes found. Create some quizzes to get started.</AlertDescription>
        </Alert>
      )}

      <Accordion type="multiple" className="space-y-4">
        {Array.from(groupedQuizzes.entries()).map(([level, levelQuizzes]) => (
          <AccordionItem key={level} value={`level-${level}`} className="border rounded-lg px-4">
            <AccordionTrigger className="hover:no-underline">
              <h3 className="text-xl font-semibold">
                Level {level} <span className="text-sm text-muted-foreground ml-2">({levelQuizzes.length} quizzes)</span>
              </h3>
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid gap-4 pt-4">
                {levelQuizzes.map((quiz) => (
                  <QuizCard
                    key={quiz.id}
                    quiz={quiz}
                    onEdit={() => onEdit(quiz)}
                    onDelete={() => onDelete(quiz)}
                    isAdmin={true}
                  />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
