
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { QuizAdminActions } from "@/components/quiz/QuizAdminActions";
import { MultiplayerQuizTemplate } from "@/types/multiplayer";
import { Quiz } from "@/types/quiz";

interface MultiplayerQuizCardProps {
  quiz: MultiplayerQuizTemplate;
  onEdit: (quiz: MultiplayerQuizTemplate) => void;
  onDelete: (quiz: MultiplayerQuizTemplate) => void;
}

export const MultiplayerQuizCard = ({ quiz, onEdit, onDelete }: MultiplayerQuizCardProps) => {
  // Convert MultiplayerQuizTemplate to Quiz type for QuizAdminActions
  const quizData: Quiz = {
    ...quiz,
    points: 0, // Default value since multiplayer quizzes don't have points
    updated_at: quiz.created_at, // Use created_at as updated_at since we don't track updates
    questions: quiz.questions.map(q => ({
      id: crypto.randomUUID(), // Generate a random ID for each question
      question: q.question,
      correct_answer: q.correct_answer,
      options: q.options,
      level: quiz.level, // Use the multiplayer quiz level
      quiz_id: quiz.id
    }))
  };

  const getLevelTypeColor = (type: 'beginner' | 'intermediate' | 'expert') => {
    switch (type) {
      case 'beginner':
        return 'text-green-500';
      case 'intermediate':
        return 'text-yellow-500';
      case 'expert':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <Card className="bg-card">
      <CardHeader>
        <div className="flex justify-between items-start">
          <CardTitle>{quiz.title || 'Untitled Quiz'}</CardTitle>
          <QuizAdminActions
            quiz={quizData}
            onEdit={() => onEdit(quiz)}
            onDelete={() => onDelete(quiz)}
          />
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-gray-500">
          {quiz.description || 'No description available'}
        </p>
        <div className="mt-2 space-y-1">
          <p className="text-sm">Questions: {quiz.questions?.length || 0}</p>
          <p className="text-sm">Time Limit: {quiz.time_limit || 30} minutes</p>
          <p className="text-sm">Level: {quiz.level}</p>
          <p className={`text-sm font-medium ${getLevelTypeColor(quiz.level_type)}`}>
            Difficulty: {quiz.level_type.charAt(0).toUpperCase() + quiz.level_type.slice(1)}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
