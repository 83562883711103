import { Crown } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface AmbassadorBadgeProps {
  rank: string;
}

export const AmbassadorBadge = ({ rank }: AmbassadorBadgeProps) => {
  return (
    <Badge className="bg-gameGold text-gamePurple flex items-center gap-1">
      <Crown className="h-4 w-4" />
      {rank}
    </Badge>
  );
};