
import { useState, useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { RatingStats, ApprovedRating } from "./types";

export const useRating = () => {
  const [user, setUser] = useState<any>(null);
  const [userRating, setUserRating] = useState<number | null>(null);
  const [hoveredRating, setHoveredRating] = useState<number | null>(null);
  const [stats, setStats] = useState<RatingStats>({ averageRating: 0, totalRatings: 0 });
  const [approvedRatings, setApprovedRatings] = useState<ApprovedRating[]>([]);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserRating(session.user.id);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        fetchUserRating(session.user.id);
      }
    });

    fetchRatingStats();
    fetchApprovedRatings();

    return () => subscription.unsubscribe();
  }, []);

  const fetchApprovedRatings = async () => {
    try {
      const { data, error } = await supabase
        .from('site_ratings')
        .select(`
          rating,
          comment,
          created_at,
          profiles:user_id (
            display_name
          )
        `)
        .eq('is_approved', true)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) throw error;

      setApprovedRatings(data as ApprovedRating[]);
    } catch (error) {
      console.error('Error fetching approved ratings:', error);
    }
  };

  const fetchRatingStats = async () => {
    try {
      const { data, error } = await supabase
        .from('site_ratings')
        .select('rating')
        .eq('is_approved', true);

      if (error) throw error;

      if (data && data.length > 0) {
        const average = data.reduce((acc, curr) => acc + curr.rating, 0) / data.length;
        setStats({
          averageRating: Number(average.toFixed(1)),
          totalRatings: data.length
        });
      }
    } catch (error) {
      console.error('Error fetching ratings:', error);
    }
  };

  const fetchUserRating = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('site_ratings')
        .select('rating, comment')
        .eq('user_id', userId)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setUserRating(data.rating);
        setSelectedRating(data.rating);
        setComment(data.comment || '');
      }
    } catch (error) {
      console.error('Error fetching user rating:', error);
    }
  };

  const handleSubmit = async () => {
    if (!user) {
      toast({
        title: "Authentication Required",
        description: "Please sign in to rate the site",
        variant: "destructive",
      });
      return;
    }

    if (selectedRating === null) {
      toast({
        title: "Rating Required",
        description: "Please select a rating before submitting",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);
    
    try {
      const { error } = await supabase
        .from('site_ratings')
        .upsert({
          user_id: user.id,
          rating: selectedRating,
          comment: comment || null,
          is_approved: false,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id'
        });

      if (error) throw error;

      setUserRating(selectedRating);
      toast({
        title: "Thanks for rating the game of sword kings",
      });
      
      fetchRatingStats();
    } catch (error: any) {
      console.error('Error submitting rating:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to submit rating. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    user,
    userRating,
    hoveredRating,
    setHoveredRating,
    stats,
    approvedRatings,
    comment,
    setComment,
    isSubmitting,
    selectedRating,
    setSelectedRating,
    handleSubmit,
  };
};

