
import React from 'react';
import { Book, Trophy, Users, Dices } from 'lucide-react';
import { Button } from "@/components/ui/button";

const features = [
  {
    icon: Book,
    title: "Biblical Knowledge",
    description: "Explore scripture through carefully crafted questions spanning both Old and New Testaments."
  },
  {
    icon: Trophy,
    title: "Earn Rewards",
    description: "Gain points and achievements as you progress through different difficulty levels."
  },
  {
    icon: Users,
    title: "Community",
    description: "Compete with other believers worldwide and see where you rank on our global leaderboard."
  },
  {
    icon: Dices,
    title: "Board Game Version",
    description: "Experience the physical board game version with family and friends. Perfect for Bible study groups and family game nights! A great gift for those you love.",
    action: {
      label: "Purchase Board Game",
      url: "https://boardgame.gameofswordkings.com"
    }
  }
];

export const FeatureCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto mb-16 px-4">
      {features.map((feature) => (
        <div key={feature.title} className="bg-gamePurple/40 p-8 rounded-lg text-center flex flex-col">
          <feature.icon className="w-12 h-12 text-gameGold mx-auto mb-4" />
          <h3 className="text-gameGold text-xl font-semibold mb-3">{feature.title}</h3>
          <p className="text-gray-300 mb-4 flex-grow">{feature.description}</p>
          {feature.action && (
            <Button 
              className="bg-gameGold hover:bg-gameGold/90 text-gamePurple w-full mt-4"
              asChild
            >
              <a href={feature.action.url} target="_blank" rel="noopener noreferrer">
                {feature.action.label}
              </a>
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};
