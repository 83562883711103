
import { useEffect } from 'react';

export const AdsenseScript = () => {
  useEffect(() => {
    try {
      const script = document.createElement('script');
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6089598110001081";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.head.appendChild(script);
    } catch (error) {
      console.error('Error loading AdSense script:', error);
    }
  }, []);

  return null;
};
