
import { Button } from "@/components/ui/button";
import { Question } from "@/types/quiz";
import { MultiplayerFormFields } from "./MultiplayerFormFields";
import { MultiplayerCsvSection } from "./MultiplayerCsvSection";
import { QuestionsList } from "@/components/quiz/QuestionsList";

interface MultiplayerTemplateFormProps {
  title: string;
  description: string;
  timeLimit: string;
  level: number;
  levelType: 'beginner' | 'intermediate' | 'expert';
  questions: Question[];
  isSubmitting: boolean;
  onTitleChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onTimeLimitChange: (value: string) => void;
  onLevelChange: (value: number) => void;
  onLevelTypeChange: (value: 'beginner' | 'intermediate' | 'expert') => void;
  onQuestionsImported: (questions: Question[]) => void;
  onSubmit: (e: React.FormEvent) => void;
  onBack: () => void;
}

export const MultiplayerTemplateForm = ({
  title,
  description,
  timeLimit,
  level,
  levelType,
  questions,
  isSubmitting,
  onTitleChange,
  onDescriptionChange,
  onTimeLimitChange,
  onLevelChange,
  onLevelTypeChange,
  onQuestionsImported,
  onSubmit,
  onBack,
}: MultiplayerTemplateFormProps) => {
  return (
    <form onSubmit={onSubmit} className="space-y-6 max-w-4xl mx-auto bg-[#020817] p-8 rounded-lg">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Create Multiplayer Quiz Template</h2>
        <Button variant="outline" onClick={onBack}>
          Back to List
        </Button>
      </div>

      <MultiplayerFormFields
        title={title}
        description={description}
        timeLimit={timeLimit}
        level={level}
        levelType={levelType}
        onTitleChange={onTitleChange}
        onDescriptionChange={onDescriptionChange}
        onTimeLimitChange={onTimeLimitChange}
        onLevelChange={onLevelChange}
        onLevelTypeChange={onLevelTypeChange}
      />

      <MultiplayerCsvSection onQuestionsImported={onQuestionsImported} />

      <QuestionsList questions={questions} />

      <div className="flex justify-end">
        <Button
          type="submit"
          disabled={isSubmitting}
          className="bg-blue-600 text-white hover:bg-blue-700"
        >
          {isSubmitting ? 'Saving...' : 'Create Multiplayer Template'}
        </Button>
      </div>
    </form>
  );
};
