
import { Messages } from "@/components/messages/Messages";
import { HomeLink } from "@/components/HomeLink";

const MessagesPage = () => {
  return (
    <div className="container mx-auto p-4">
      <HomeLink />
      <Messages />
    </div>
  );
};

export default MessagesPage;
