
import React, { useEffect, useState, useCallback } from 'react';
import { HomeLink } from '@/components/HomeLink';
import { Button } from '@/components/ui/button';
import { useMultiplayerGame } from '@/hooks/use-multiplayer-game';
import { MultiplayerWaitingRoom } from './MultiplayerWaitingRoom';
import { MultiplayerGameOver } from './MultiplayerGameOver';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';

export const MultiplayerGame = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [localLoading, setLocalLoading] = useState(true);
  
  const {
    room,
    participants,
    currentQuestion,
    selectedAnswer,
    isReady,
    isLoading,
    setSelectedAnswer,
    setPlayerReady,
    submitAnswer,
    handleGameEnd,
    leaveGame
  } = useMultiplayerGame();

  // Set a short timeout to ensure stable loading state and prevent flickering
  useEffect(() => {
    const timer = setTimeout(() => {
      setLocalLoading(isLoading);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [isLoading]);

  const handleExit = useCallback(async () => {
    await leaveGame();
    navigate('/multiplayer');
  }, [leaveGame, navigate]);

  // Show loader during initial loading
  if (localLoading) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <HomeLink />
        <div className="flex flex-col items-center justify-center h-64">
          <Loader2 className="h-10 w-10 animate-spin text-gameGold mb-4" />
          <p className="text-white">Loading game...</p>
        </div>
      </div>
    );
  }

  if (!room) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <HomeLink />
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gameGold mb-4">Room Not Found</h2>
          <p className="text-white mb-6">This game room is no longer available.</p>
          <Button onClick={() => navigate('/multiplayer')}>Return to Lobby</Button>
        </div>
      </div>
    );
  }

  if (room.status === 'waiting') {
    return (
      <MultiplayerWaitingRoom
        participants={participants}
        isReady={isReady}
        onReady={setPlayerReady}
        quizTitle={room.quiz_title}
      />
    );
  }

  // Enhanced quiz data validation
  const hasValidQuizData = room.quiz_data && 
                       room.quiz_data.questions && 
                       Array.isArray(room.quiz_data.questions) && 
                       room.quiz_data.questions.length > 0;

  if (!hasValidQuizData) {
    console.error('Invalid quiz data:', room.quiz_data);
    
    toast({
      title: "Error",
      description: "Quiz data is invalid or missing",
      variant: "destructive",
    });
    
    return (
      <div className="container mx-auto px-4 py-8">
        <HomeLink />
        <div className="text-center mt-8">
          <h2 className="text-2xl font-bold text-gameGold mb-4">Error Loading Quiz</h2>
          <p className="text-white mb-4">There was a problem loading the quiz questions.</p>
          <Button onClick={handleExit}>Return to Lobby</Button>
        </div>
      </div>
    );
  }

  const currentQuestionIndex = currentQuestion;
  const questions = room.quiz_data.questions;
  const currentQuestionData = questions[currentQuestionIndex];

  // Check for game over condition
  if (!currentQuestionData || currentQuestionIndex >= questions.length) {
    return (
      <MultiplayerGameOver 
        participants={participants}
        onGameEnd={handleGameEnd}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <HomeLink />
        <Button 
          variant="destructive" 
          onClick={handleExit}
          className="ml-4"
        >
          Exit Game
        </Button>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gameGold">
          Question {currentQuestionIndex + 1} of {questions.length}
        </h2>
        {participants.map((participant) => (
          <div key={participant.user_id} className="text-gameGold">
            {participant.profiles?.display_name || participant.user_id}: {participant.score || 0} points
          </div>
        ))}
      </div>

      <div className="bg-gamePurple/30 p-6 rounded-lg">
        <div className="min-h-[100px] flex items-center mb-6">
          <h3 className="text-xl text-gameGold">{currentQuestionData.question}</h3>
        </div>
        <div className="grid gap-4">
          {currentQuestionData.options && Array.isArray(currentQuestionData.options) && 
            currentQuestionData.options.map((option: string) => (
              <Button
                key={option}
                onClick={() => setSelectedAnswer(option)}
                variant={selectedAnswer === option ? "secondary" : "outline"}
                className="w-full text-left min-h-[48px] whitespace-normal"
              >
                {option}
              </Button>
            ))
          }
        </div>
        {selectedAnswer && (
          <Button onClick={submitAnswer} className="mt-4">
            Submit Answer
          </Button>
        )}
      </div>
    </div>
  );
};
