
import { Loader2 } from "lucide-react";

export const AdminLoading = () => {
  return (
    <div className="flex items-center justify-center min-h-[50vh] md:min-h-screen">
      <Loader2 className="w-6 h-6 md:w-8 md:h-8 animate-spin text-primary" />
    </div>
  );
};

