
import { useState, useCallback, useRef, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { useNavigate } from 'react-router-dom';
import { RealtimeChannel } from '@supabase/supabase-js';
import { useRoomPresence } from './use-room-presence';

export const useRoomSubscription = (
  roomId: string | undefined, 
  onDataChange: () => Promise<void>
) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [connectionStatus, setConnectionStatus] = useState<'connected' | 'disconnected'>('disconnected');
  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastUpdateRef = useRef<number>(0);
  
  // Prevent rapid consecutive updates using debounce
  const handleDataUpdate = useCallback(() => {
    const now = Date.now();
    // Only process updates if more than 500ms have passed since the last one
    if (now - lastUpdateRef.current > 500) {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
      
      updateTimeoutRef.current = setTimeout(async () => {
        await onDataChange();
        lastUpdateRef.current = Date.now();
        updateTimeoutRef.current = null;
      }, 200);
    }
  }, [onDataChange]);
  
  const {
    channelRef,
    handlePresenceSync,
    handlePresenceJoin,
    handlePresenceLeave,
    trackPresence
  } = useRoomPresence(roomId, handleDataUpdate);

  // Clean up any timeout on unmount
  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  const subscribeToRoom = useCallback(() => {
    if (!roomId) return null;

    if (channelRef.current) {
      console.log('Cleaning up existing channel');
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }

    console.log('Setting up room subscription:', roomId);
    
    const channel = supabase.channel(`room:${roomId}`)
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public',
          table: 'multiplayer_rooms',
          filter: `id=eq.${roomId}`
        },
        async (payload: { new: any }) => {
          console.log('Room change detected:', payload);
          if (payload.new?.status === 'completed') {
            toast({
              title: "Game Over",
              description: "This game has ended. Returning to lobby.",
            });
            navigate('/multiplayer');
          } else if (payload.new) {
            handleDataUpdate();
          }
        }
      )
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public',
          table: 'multiplayer_participants',
          filter: `room_id=eq.${roomId}`
        },
        () => {
          console.log('Participants change detected');
          handleDataUpdate();
        }
      )
      .on('presence', { event: 'sync' }, handlePresenceSync)
      .on('presence', { event: 'join' }, handlePresenceJoin)
      .on('presence', { event: 'leave' }, handlePresenceLeave);

    channel.subscribe(async (status: string) => {
      if (status === 'SUBSCRIBED') {
        console.log('Successfully subscribed to room changes');
        setConnectionStatus('connected');
        await trackPresence(channel);
      } else {
        setConnectionStatus('disconnected');
      }
    });

    channelRef.current = channel;
    
    return channel;
  }, [roomId, navigate, toast, handleDataUpdate, handlePresenceSync, handlePresenceJoin, handlePresenceLeave, trackPresence]);

  return {
    connectionStatus,
    subscribeToRoom,
  };
};
