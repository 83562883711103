
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';

interface CallToActionProps {
  isAuthenticated: boolean;
}

export const CallToAction = ({ isAuthenticated }: CallToActionProps) => {
  if (!isAuthenticated) {
    return (
      <div className="flex gap-4 justify-center">
        <Link 
          to="/auth?mode=signup" 
          className="flex-1 bg-gameGold text-gamePurple px-8 py-3 rounded-md font-semibold text-lg hover:bg-gameGold/90 transition-colors text-center"
        >
          Sign Up Now
        </Link>
        <Link 
          to="/auth"
          className="flex-1 bg-emerald-500 text-white px-8 py-3 rounded-md font-semibold text-lg hover:bg-emerald-600 transition-colors text-center"
        >
          Sign In
        </Link>
      </div>
    );
  }

  return (
    <>
      <Link 
        to="/levels" 
        className="bg-gameGold text-gamePurple px-8 py-3 rounded-md font-semibold text-lg hover:bg-gameGold/90 transition-colors"
      >
        Start Your Quest
      </Link>
      <Link 
        to="/multiplayer" 
        className="bg-gameGold text-gamePurple px-8 py-3 rounded-md font-semibold text-lg hover:bg-gameGold/90 transition-colors"
      >
        Multiplayer Battle
      </Link>
    </>
  );
};
