
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { publicRoutes } from '@/routes/publicRoutes';
import { protectedRoutes } from '@/routes/protectedRoutes';
import { adminRoutes } from '@/routes/adminRoutes';
import { ProtectedRoute } from '@/components/routing/ProtectedRoute';
import { AdminRoute } from '@/components/routing/AdminRoute';
import Index from '@/pages/Index';
import { useToast } from '@/hooks/use-toast';
import { useEffect } from 'react';

function App() {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Set up auth state listener
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT' || event === 'TOKEN_REFRESHED') {
        // Invalidate and refetch queries when auth state changes
        queryClient.invalidateQueries({ queryKey: ['session'] });
        queryClient.invalidateQueries({ queryKey: ['profile'] });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient]);

  const { data: session, isLoading: isSessionLoading, error: sessionError } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) {
          console.error('Error fetching session:', error);
          throw error;
        }
        return session;
      } catch (error: any) {
        console.error('Session error:', error);
        // Only show toast for non-refresh token errors
        if (error.message && !error.message.includes('refresh_token_not_found')) {
          toast({
            title: 'Session Error',
            description: 'Please sign in again',
            variant: 'destructive',
          });
        }
        return null;
      }
    },
    retry: false, // Don't retry on failure
    staleTime: 1000 * 60 * 5, // Consider data stale after 5 minutes
  });

  const { data: profile, isLoading: isProfileLoading } = useQuery({
    queryKey: ['profile', session?.user?.id],
    queryFn: async () => {
      if (!session?.user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching profile:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch profile. Please try again.',
          variant: 'destructive',
        });
        throw error;
      }
      return data;
    },
    enabled: !!session?.user?.id,
    staleTime: 1000 * 60 * 5, // Keep profile data fresh for 5 minutes
  });

  // Show loading state while checking authentication
  if (isSessionLoading || isProfileLoading) {
    return <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate flex items-center justify-center">
      <div className="text-gameGold">Loading...</div>
    </div>;
  }

  // If there's a session error, clear it and redirect to auth
  if (sessionError) {
    // Let the auth page handle the redirect if needed
    return <Navigate to="/auth" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate">
      <Routes>
        {/* Public Routes */}
        <Route path="/home" element={<Index />} />
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.path === '/auth' && session ? (
                <Navigate to="/" replace />
              ) : (
                route.element
              )
            }
          />
        ))}

        {/* Protected Routes */}
        {protectedRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute isAuthenticated={!!session}>
                {route.element}
              </ProtectedRoute>
            }
          />
        ))}

        {/* Admin Routes */}
        {adminRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute isAuthenticated={!!session}>
                <AdminRoute isAdmin={!!profile?.is_admin}>
                  {route.element}
                </AdminRoute>
              </ProtectedRoute>
            }
          />
        ))}

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
