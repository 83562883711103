import { Navigate } from 'react-router-dom';

interface AdminRouteProps {
  children: React.ReactNode;
  isAdmin: boolean;
}

export const AdminRoute = ({ children, isAdmin }: AdminRouteProps) => {
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};