
import { supabase } from '@/integrations/supabase/client';
import { cleanupStaleParticipation } from './participant-management';

export const createNewRoom = async (templateId: string, userId: string) => {
  try {
    console.log('Creating new room with template:', templateId, 'for user:', userId);
    
    // First ensure any stale participations are cleaned up
    await cleanupStaleParticipation(userId);
    
    // Then check for active participation
    const { data: existingParticipation, error: participationError } = await supabase
      .from('multiplayer_participants')
      .select('room_id, multiplayer_rooms!inner(status)')
      .eq('user_id', userId)
      .neq('multiplayer_rooms.status', 'completed');

    if (participationError) {
      console.error('Error checking existing participation:', participationError);
      throw participationError;
    }

    if (existingParticipation && existingParticipation.length > 0) {
      console.log('User already in room:', existingParticipation);
      
      // Get IDs of rooms user is participating in
      const roomIds = existingParticipation.map(p => p.room_id);
      
      // Force cleanup of any waiting rooms (user may have left the page without properly leaving)
      const { data: waitingRooms } = await supabase
        .from('multiplayer_rooms')
        .select('id')
        .in('id', roomIds)
        .eq('status', 'waiting');
        
      if (waitingRooms && waitingRooms.length > 0) {
        const waitingRoomIds = waitingRooms.map(r => r.id);
        
        // Remove user from waiting rooms
        const { error: cleanupError } = await supabase
          .from('multiplayer_participants')
          .delete()
          .eq('user_id', userId)
          .in('room_id', waitingRoomIds);
          
        if (cleanupError) {
          console.error('Error cleaning up waiting rooms:', cleanupError);
          throw new Error('Error cleaning up previous room participation');
        }
        
        console.log(`Cleaned up user participation in ${waitingRoomIds.length} waiting rooms`);
      } else {
        // If user is in in-progress rooms, don't allow creating a new room
        throw new Error('You are already in another room. Please leave that room first.');
      }
    }

    // Fetch the selected template
    const { data: template, error: templateError } = await supabase
      .from('multiplayer_quiz_templates')
      .select('*')
      .eq('id', templateId)
      .single();

    if (templateError) {
      console.error('Error fetching template:', templateError);
      throw templateError;
    }

    if (!template) {
      console.error('Template not found:', templateId);
      throw new Error('Quiz template not found');
    }

    // Create the room
    const { data: room, error: roomError } = await supabase
      .from('multiplayer_rooms')
      .insert([
        {
          quiz_data: template.questions,
          quiz_title: template.title,
          status: 'waiting',
          current_question_index: 0
        }
      ])
      .select()
      .single();

    if (roomError) {
      console.error('Error creating room:', roomError);
      throw roomError;
    }

    if (!room) {
      console.error('Room was not created');
      throw new Error('Failed to create room: No room data returned');
    }

    // Add the creator as first participant
    const { error: participantError } = await supabase
      .from('multiplayer_participants')
      .insert([
        {
          room_id: room.id,
          user_id: userId,
          score: 0,
          is_ready: false
        }
      ]);

    if (participantError) {
      console.error('Error adding participant:', participantError);
      await supabase
        .from('multiplayer_rooms')
        .delete()
        .eq('id', room.id);
      throw participantError;
    }

    return room;
  } catch (error) {
    console.error('Error in createNewRoom:', error);
    throw error;
  }
};
