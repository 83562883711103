
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { LogOut, Ban, Trash2, RotateCcw, MessageSquare } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { type Profile } from "../types";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";

interface UserActionsProps {
  profile: Profile;
  onToggleAdmin: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onToggleRestriction: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onDeleteUser: (profileId: string) => Promise<void>;
  onMessageUser?: (profile: Profile) => void;
  showMessageAction?: boolean;
}

export const UserActions = ({ 
  profile, 
  onToggleAdmin, 
  onToggleRestriction, 
  onDeleteUser,
  onMessageUser,
  showMessageAction = false
}: UserActionsProps) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetPointsDialog, setShowResetPointsDialog] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  const handleResetPoints = async () => {
    try {
      const { error } = await supabase.rpc('reset_user_points', {
        user_id: profile.id
      });

      if (error) {
        throw error;
      }

      await queryClient.invalidateQueries({ queryKey: ['profiles'] });

      toast({
        title: "Points Reset",
        description: `Successfully reset points for ${profile.username || 'user'}`,
      });
      
      setShowResetPointsDialog(false);
    } catch (error) {
      console.error('Error resetting points:', error);
      toast({
        title: "Error",
        description: "Failed to reset user points. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <Button
          variant={profile.is_admin ? "destructive" : "default"}
          onClick={() => onToggleAdmin(profile.id, profile.is_admin)}
          className="w-full"
        >
          {profile.is_admin ? 'Remove Admin' : 'Make Admin'}
        </Button>
        <Button
          variant={profile.is_restricted ? "outline" : "secondary"}
          onClick={() => onToggleRestriction(profile.id, profile.is_restricted)}
          className="w-full flex items-center justify-center gap-2"
        >
          {profile.is_restricted ? (
            <>
              <LogOut className="h-4 w-4" />
              <span>Restore Access</span>
            </>
          ) : (
            <>
              <Ban className="h-4 w-4" />
              <span>Restrict Access</span>
            </>
          )}
        </Button>
        {showMessageAction && (
          <Button
            variant="outline"
            onClick={() => onMessageUser?.(profile)}
            className="w-full flex items-center justify-center gap-2"
          >
            <MessageSquare className="h-4 w-4" />
            <span>Send Message</span>
          </Button>
        )}
        <Button
          variant="secondary"
          onClick={() => setShowResetPointsDialog(true)}
          className="w-full flex items-center justify-center gap-2"
        >
          <RotateCcw className="h-4 w-4" />
          <span>Reset Points</span>
        </Button>
        <Button
          variant="destructive"
          onClick={() => setShowDeleteDialog(true)}
          className="w-full flex items-center justify-center gap-2"
        >
          <Trash2 className="h-4 w-4" />
          <span>Delete User</span>
        </Button>
      </div>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the user
              account and remove their data from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                onDeleteUser(profile.id);
                setShowDeleteDialog(false);
              }}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={showResetPointsDialog} onOpenChange={setShowResetPointsDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Reset User Points?</AlertDialogTitle>
            <AlertDialogDescription>
              This will reset {profile.username || 'this user'}'s points to 0. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleResetPoints}
              className="bg-secondary text-secondary-foreground hover:bg-secondary/90"
            >
              Reset Points
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
