
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { QuizManagerAlerts } from "@/components/quiz/QuizManagerAlerts";
import { useToast } from "@/hooks/use-toast";
import { MultiplayerQuizCard } from "./MultiplayerQuizCard";
import { MultiplayerQuizEditForm } from "./MultiplayerQuizEditForm";
import { MultiplayerQuizTemplate } from "@/types/multiplayer";

export const MultiplayerQuizList = () => {
  const { toast } = useToast();
  const [editingQuiz, setEditingQuiz] = useState<MultiplayerQuizTemplate | null>(null);
  
  const { data: templates, isLoading, error, refetch } = useQuery({
    queryKey: ["multiplayer-templates"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("multiplayer_quiz_templates")
        .select("*")
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as MultiplayerQuizTemplate[];
    },
  });

  const handleEdit = (template: MultiplayerQuizTemplate) => {
    setEditingQuiz(template);
  };

  const handleDelete = async (template: MultiplayerQuizTemplate) => {
    try {
      const { error } = await supabase
        .from("multiplayer_quiz_templates")
        .delete()
        .eq("id", template.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Template deleted successfully",
      });

      refetch();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const handleTemplateSaved = () => {
    setEditingQuiz(null);
    refetch();
  };

  if (editingQuiz) {
    return (
      <MultiplayerQuizEditForm 
        quiz={editingQuiz}
        onBack={() => setEditingQuiz(null)}
        onSuccess={handleTemplateSaved}
      />
    );
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Multiplayer Quiz Templates</h2>
      <QuizManagerAlerts isLoading={isLoading} error={error as Error | null} />
      
      {templates?.map((template) => (
        <MultiplayerQuizCard
          key={template.id}
          quiz={template}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ))}
      
      {!isLoading && (!templates || templates.length === 0) && (
        <div className="text-center py-4">
          No multiplayer quiz templates available.
        </div>
      )}
    </div>
  );
};
