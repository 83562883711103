
import { supabase } from "@/integrations/supabase/client";

interface SignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  country: string;
  metadata?: {
    username?: string;
    displayName?: string;
  };
}

export const signUp = async ({ email, password, firstName, lastName, country, metadata }: SignUpData) => {
  try {
    console.log("Starting sign up process...");
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          firstName,
          lastName,
          country,
          username: metadata?.username,
          displayName: metadata?.displayName,
        },
      },
    });

    if (error) {
      console.error("Sign up error:", error);
      throw error;
    }
    
    console.log("Sign up successful, user:", data.user);
    
    // Since email confirmation is disabled, we can proceed with sign in
    if (data.user) {
      return signIn(email, password);
    }
    
    return { data, error };
  } catch (error) {
    console.error("Error in signUp function:", error);
    throw error;
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    console.log("Starting sign in process...");
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    
    if (error) {
      console.error("Sign in error:", error);
      throw error;
    }
    
    console.log("Sign in successful, session:", data.session);
    return { data, error };
  } catch (error) {
    console.error("Error in signIn function:", error);
    throw error;
  }
};

export const signOut = async () => {
  try {
    // Clear any stored session data
    localStorage.removeItem('supabase.auth.token');
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Sign out error:", error);
      throw error;
    }
    return { error: null };
  } catch (error) {
    console.error("Error in signOut function:", error);
    return { error };
  }
};

export const getSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      console.error("Get session error:", error);
      throw error;
    }
    
    // If no session, try to refresh
    if (!session) {
      const { data: { session: refreshedSession }, error: refreshError } = await supabase.auth.refreshSession();
      if (refreshError) {
        console.error("Refresh session error:", refreshError);
        throw refreshError;
      }
      return { session: refreshedSession, error: null };
    }
    
    return { session, error: null };
  } catch (error) {
    console.error('Session error:', error);
    return { session: null, error };
  }
};
