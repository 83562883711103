
import { Star } from "lucide-react";

interface RatingStarsProps {
  rating: number | null;
  hoveredRating: number | null;
  selectedRating: number | null;
  isSubmitting: boolean;
  onHover: (rating: number | null) => void;
  onClick: (rating: number) => void;
}

export const RatingStars = ({
  rating,
  hoveredRating,
  selectedRating,
  isSubmitting,
  onHover,
  onClick,
}: RatingStarsProps) => {
  return (
    <div className="flex gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          className="focus:outline-none focus:ring-2 focus:ring-gameGold rounded-full p-1"
          onMouseEnter={() => onHover(star)}
          onMouseLeave={() => onHover(null)}
          onClick={() => onClick(star)}
          disabled={isSubmitting}
          aria-label={`Rate ${star} out of 5 stars`}
        >
          <Star
            className={`w-6 h-6 ${
              (hoveredRating !== null ? star <= hoveredRating : star <= (selectedRating || 0))
                ? 'fill-gameGold text-gameGold'
                : 'text-gray-400'
            } transition-colors`}
          />
        </button>
      ))}
    </div>
  );
};
