
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { formatDistanceToNow } from "date-fns";

type AdminMessage = {
  id: string;
  content: string;
  subject: string;
  created_at: string;
  status: 'unread' | 'read' | 'archived';
  sender_id: string;
  sender: {
    username: string | null;
    display_name: string | null;
  };
};

type BroadcastMessage = {
  id: string;
  content: string;
  subject: string;
  created_at: string;
  target_role: 'all' | 'admin' | 'ambassador' | 'user';
};

export const Messages = () => {
  const [activeTab, setActiveTab] = useState<"direct" | "broadcast">("direct");

  const { data: directMessages = [] } = useQuery<AdminMessage[]>({
    queryKey: ['direct-messages'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('admin_messages')
        .select(`
          id,
          content,
          subject,
          created_at,
          status,
          sender_id,
          sender:profiles!sender_id (
            username,
            display_name
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    }
  });

  const { data: broadcastMessages = [] } = useQuery<BroadcastMessage[]>({
    queryKey: ['broadcast-messages'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('broadcast_messages')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    }
  });

  const formatDate = (date: string) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-2xl font-bold mb-6 text-gameGold">Notifications</h1>
      
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as "direct" | "broadcast")}>
        <TabsList className="mb-4">
          <TabsTrigger value="direct">Direct Notifications</TabsTrigger>
          <TabsTrigger value="broadcast">Broadcast Notifications</TabsTrigger>
        </TabsList>

        <TabsContent value="direct">
          <div className="space-y-4">
            {directMessages.map((message) => (
              <Card key={message.id} className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-semibold text-lg text-gameGold">{message.subject}</h3>
                    <p className="text-sm text-gray-400">
                      From: {message.sender?.display_name || message.sender?.username || 'Admin'}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Badge variant={message.status === 'unread' ? 'default' : 'secondary'}>
                      {message.status}
                    </Badge>
                    <span className="text-sm text-gray-400">
                      {formatDate(message.created_at)}
                    </span>
                  </div>
                </div>
                <p className="text-gray-200">{message.content}</p>
              </Card>
            ))}
            {directMessages.length === 0 && (
              <p className="text-center text-gray-400">No direct notifications</p>
            )}
          </div>
        </TabsContent>

        <TabsContent value="broadcast">
          <div className="space-y-4">
            {broadcastMessages.map((message) => (
              <Card key={message.id} className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-semibold text-lg text-gameGold">{message.subject}</h3>
                    <Badge variant="outline" className="text-xs">
                      {message.target_role === 'all' ? 'Everyone' : `${message.target_role}s`}
                    </Badge>
                  </div>
                  <span className="text-sm text-gray-400">
                    {formatDate(message.created_at)}
                  </span>
                </div>
                <p className="text-gray-200 mt-2">{message.content}</p>
              </Card>
            ))}
            {broadcastMessages.length === 0 && (
              <p className="text-center text-gray-400">No broadcast notifications</p>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
