
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Mail } from "lucide-react";
import { type Profile } from "../types";

interface AdminMessageFormProps {
  recipient: Profile;
  onClose: () => void;
}

export const AdminMessageForm = ({ recipient, onClose }: AdminMessageFormProps) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { error } = await supabase
        .from('admin_messages')
        .insert({
          sender_id: user.id,
          recipient_id: recipient.id,
          subject,
          content
        });

      if (error) throw error;

      toast({
        title: "Message Sent",
        description: `Message sent to ${recipient.username || recipient.display_name}`,
      });
      
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <p className="text-sm text-muted-foreground mb-4">
          Sending message to: {recipient.username || recipient.display_name}
        </p>
      </div>
      <div className="space-y-2">
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <div className="space-y-2">
        <Textarea
          placeholder="Message content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
          rows={5}
        />
      </div>
      <div className="flex justify-end gap-2">
        <Button type="button" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSending}>
          <Mail className="w-4 h-4 mr-2" />
          {isSending ? "Sending..." : "Send Message"}
        </Button>
      </div>
    </form>
  );
};
