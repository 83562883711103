
import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { getMetrics } from '@/services/multiplayer/websocket-service';
import { supabase } from '@/integrations/supabase/client';
import { MultiplayerRoom } from '@/types/multiplayer-room';

export const MultiplayerMonitoring = () => {
  const [metrics, setMetrics] = useState<any>(null);
  const [activeRooms, setActiveRooms] = useState<MultiplayerRoom[]>([]);

  useEffect(() => {
    const metricsInterval = setInterval(() => {
      const currentMetrics = getMetrics();
      setMetrics(currentMetrics);
    }, 1000);

    // Subscribe to room changes
    const roomChannel = supabase
      .channel('admin-room-updates')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'multiplayer_rooms'
        },
        () => {
          fetchActiveRooms();
        }
      )
      .subscribe();

    // Subscribe to participant changes
    const participantChannel = supabase
      .channel('admin-participant-updates')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'multiplayer_participants'
        },
        () => {
          fetchActiveRooms();
        }
      )
      .subscribe();

    fetchActiveRooms();

    return () => {
      clearInterval(metricsInterval);
      supabase.removeChannel(roomChannel);
      supabase.removeChannel(participantChannel);
    };
  }, []);

  const fetchActiveRooms = async () => {
    const oneHourAgo = new Date();
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);

    const { data, error } = await supabase
      .from('multiplayer_rooms')
      .select(`
        *,
        multiplayer_participants (
          user_id,
          is_ready,
          profiles:user_id (
            display_name
          )
        )
      `)
      .in('status', ['waiting', 'in_progress'])
      .gt('created_at', oneHourAgo.toISOString())
      .order('created_at', { ascending: false });

    if (!error && data) {
      // Filter out rooms without participants
      const roomsWithParticipants = data.filter(room => 
        room.multiplayer_participants && 
        room.multiplayer_participants.length > 0
      );

      // Clean up abandoned rooms
      data.forEach(async (room) => {
        if (!room.multiplayer_participants || room.multiplayer_participants.length === 0) {
          await supabase
            .from('multiplayer_rooms')
            .delete()
            .eq('id', room.id);
        }
      });

      const transformedRooms = roomsWithParticipants.map(room => ({
        ...room,
        quiz_data: typeof room.quiz_data === 'string' 
          ? JSON.parse(room.quiz_data)
          : room.quiz_data
      })) as MultiplayerRoom[];
      
      setActiveRooms(transformedRooms);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'connected':
        return 'bg-green-500';
      case 'reconnecting':
        return 'bg-yellow-500';
      case 'disconnected':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  const getRoomStatusBadgeVariant = (status: string) => {
    switch (status) {
      case 'waiting':
        return 'secondary';
      case 'in_progress':
        return 'default';
      case 'completed':
        return 'outline';
      default:
        return 'secondary';
    }
  };

  return (
    <div className="space-y-6">
      {/* WebSocket Metrics */}
      <Card>
        <CardHeader>
          <CardTitle>WebSocket Metrics</CardTitle>
        </CardHeader>
        <CardContent>
          {metrics && (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Connection Status</p>
                <Badge className={getStatusColor(metrics.connectionStatus)}>
                  {metrics.connectionStatus}
                </Badge>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Message Count</p>
                <p className="text-2xl font-bold">{metrics.metrics.messageCount}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Average Latency</p>
                <p className="text-2xl font-bold">
                  {metrics.metrics.latency.length > 0
                    ? `${Math.round(
                        metrics.metrics.latency.reduce((a: number, b: number) => a + b, 0) /
                          metrics.metrics.latency.length
                      )}ms`
                    : 'N/A'}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Reconnect Attempts</p>
                <p className="text-2xl font-bold">{metrics.metrics.reconnectAttempts}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Last Error</p>
                <p className="text-sm text-red-500">
                  {metrics.metrics.errors.length > 0
                    ? metrics.metrics.errors[metrics.metrics.errors.length - 1].message
                    : 'None'}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Active Rooms</p>
                <p className="text-2xl font-bold">{activeRooms.length}</p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Active Rooms */}
      <Card>
        <CardHeader>
          <CardTitle>Active Rooms</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Room ID</TableHead>
                <TableHead>Quiz Title</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Players</TableHead>
                <TableHead>Started At</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {activeRooms.map((room) => (
                <TableRow key={room.id}>
                  <TableCell className="font-mono">{room.id}</TableCell>
                  <TableCell>{room.quiz_title}</TableCell>
                  <TableCell>
                    <Badge
                      variant={getRoomStatusBadgeVariant(room.status)}
                    >
                      {room.status}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    {room.multiplayer_participants?.map((p: any) => (
                      <span key={p.user_id} className="mr-2">
                        {p.profiles.display_name}
                        {p.is_ready && ' ✓'}
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    {room.started_at
                      ? new Date(room.started_at).toLocaleString()
                      : 'Not started'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};
