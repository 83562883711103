
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { signUp, signIn } from "@/utils/auth";

export const useAuthForm = (initialSignUpMode = false) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(initialSignUpMode);
  const navigate = useNavigate();
  const { toast } = useToast();

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email.trim());
  };

  const validateForm = () => {
    const trimmedEmail = email.trim();
    
    if (!trimmedEmail || !password) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Email and password are required.",
      });
      return false;
    }

    if (!validateEmail(trimmedEmail)) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please enter a valid email address.",
      });
      return false;
    }

    if (isSignUp) {
      if (!firstName || !lastName || !country) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "All fields marked with * are required.",
        });
        return false;
      }

      if (!acceptedTerms) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "You must accept the terms and conditions to create an account.",
        });
        return false;
      }

      if (password.length < 6) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Password must be at least 6 characters long.",
        });
        return false;
      }
    }

    return true;
  };

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      if (isSignUp) {
        // For signup flow
        console.log("Attempting to sign up with:", { email: email.trim(), firstName, lastName, country });
        const { error } = await signUp({ 
          email: email.trim(), 
          password, 
          firstName, 
          lastName, 
          country,
          metadata: {
            username: email.trim(),
            displayName: `${firstName} ${lastName}`.trim(),
          }
        });
        
        if (error) throw error;
        
        navigate("/");
        toast({
          title: "Welcome!",
          description: "Your account has been created successfully. You can now sign in.",
        });
      } else {
        // For sign in flow
        console.log("Attempting to sign in with:", email.trim());
        const { error } = await signIn(email.trim(), password);
        
        if (error) throw error;
        
        navigate("/");
        toast({
          title: "Welcome back!",
          description: "You have successfully signed in.",
        });
      }
    } catch (error: any) {
      console.error("Auth error:", error);
      
      let errorMessage = "An error occurred during authentication. Please try again.";
      
      if (error.message && error.message.includes("invalid_credentials")) {
        errorMessage = "Invalid email or password. Please check your credentials and try again.";
      } else if (error.message && error.message.includes("User already registered")) {
        errorMessage = "This email is already registered. Please sign in instead.";
      } else if (error.message && error.message.includes("Password should be at least 6 characters")) {
        errorMessage = "Password must be at least 6 characters long.";
      } else if (error.message && error.message.includes("Invalid email")) {
        errorMessage = "Please enter a valid email address.";
      }

      toast({
        variant: "destructive",
        title: "Authentication Error",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    country,
    setCountry,
    acceptedTerms,
    setAcceptedTerms,
    isLoading,
    isSignUp,
    setIsSignUp,
    handleAuth,
  };
};
