
import { QuizWebSocketState } from './types';

let activeChannels: Map<string, QuizWebSocketState> = new Map();

export const getState = (roomId: string): QuizWebSocketState | undefined => {
  return activeChannels.get(roomId);
};

export const setState = (roomId: string, state: QuizWebSocketState) => {
  activeChannels.set(roomId, state);
};

export const removeState = (roomId: string) => {
  activeChannels.delete(roomId);
};

export const getAllStates = () => {
  return Array.from(activeChannels.entries());
};

export const updateConnectionStatus = (roomId: string, status: QuizWebSocketState['connectionStatus']) => {
  const state = getState(roomId);
  if (state) {
    state.connectionStatus = status;
  }
};

export const updateLastPing = (roomId: string) => {
  const state = getState(roomId);
  if (state) {
    state.lastPing = Date.now();
  }
};
