
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface AdminNavigationProps {
  activeTab: 'users' | 'quizzes' | 'levels' | 'multiplayer' | 'ads' | 'ambassadors' | 'messages' | 'ratings';
  onTabChange: (tab: 'users' | 'quizzes' | 'levels' | 'multiplayer' | 'ads' | 'ambassadors' | 'messages' | 'ratings') => void;
}

export const AdminNavigation = ({ activeTab, onTabChange }: AdminNavigationProps) => {
  return (
    <Tabs value={activeTab} className="w-full" onValueChange={(value: any) => onTabChange(value)}>
      <TabsList className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-8 w-full gap-2 sm:gap-0">
        <TabsTrigger value="users">Users</TabsTrigger>
        <TabsTrigger value="quizzes">Quizzes</TabsTrigger>
        <TabsTrigger value="levels">Levels</TabsTrigger>
        <TabsTrigger value="multiplayer">Multiplayer</TabsTrigger>
        <TabsTrigger value="ads">Ads</TabsTrigger>
        <TabsTrigger value="ambassadors">Ambassadors</TabsTrigger>
        <TabsTrigger value="messages">Messages</TabsTrigger>
        <TabsTrigger value="ratings">Ratings</TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
