
import { Navigate } from 'react-router-dom';
import { MultiplayerGame } from '@/components/multiplayer/MultiplayerGame';
import { MultiplayerLobby } from '@/components/multiplayer/MultiplayerLobby';
import { supabase } from "@/integrations/supabase/client";
import Levels from '@/pages/Levels';
import Profile from '@/pages/Profile';
import Referrals from '@/pages/Referrals';
import Quiz from '@/pages/Quiz';
import MessagesPage from '@/pages/Messages';
import { ProtectedRoute } from '@/components/routing/ProtectedRoute';

export const protectedRoutes = [
  { 
    path: "/levels", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <Levels />
      </ProtectedRoute>
    )
  },
  { 
    path: "/quiz/:id", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <Quiz />
      </ProtectedRoute>
    )
  },
  { 
    path: "/profile", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <Profile />
      </ProtectedRoute>
    )
  },
  { 
    path: "/referrals", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <Referrals />
      </ProtectedRoute>
    )
  },
  { 
    path: "/multiplayer", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <MultiplayerLobby />
      </ProtectedRoute>
    )
  },
  { 
    path: "/multiplayer/:roomId", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <MultiplayerGame />
      </ProtectedRoute>
    )
  },
  { 
    path: "/messages", 
    element: (
      <ProtectedRoute isAuthenticated={false}>
        <MessagesPage />
      </ProtectedRoute>
    )
  },
];
