
import { Link } from "react-router-dom";
import { Home } from "lucide-react";

export const HomeLink = () => {
  return (
    <Link 
      to="/" 
      className="text-gameGold hover:text-gameGold/80 flex items-center gap-2 bg-background/90 p-2 rounded-lg shadow-md"
    >
      <Home className="w-5 h-5" />
      <span>Return Home</span>
    </Link>
  );
};
