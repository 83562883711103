
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { MultiplayerRoom } from '@/types/multiplayer-room';
import {
  fetchActiveRooms,
  createNewRoom,
  joinExistingRoom,
  leaveRoom
} from '@/services/multiplayer';

const MAX_PARTICIPANTS = 2;

export const useMultiplayerRooms = () => {
  const [rooms, setRooms] = useState<MultiplayerRoom[]>([]);
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser();
    fetchRooms();
    const unsubscribe = subscribeToRooms();
    return () => {
      unsubscribe();
    };
  }, []);

  const getCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      setCurrentUser(user.id);
    }
  };

  const fetchRooms = async () => {
    setIsLoading(true);
    try {
      const activeRooms = await fetchActiveRooms();
      setRooms(activeRooms);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch rooms",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeToRooms = () => {
    const channel = supabase
      .channel('room_updates')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'multiplayer_rooms' },
        () => {
          fetchRooms();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  const createRoom = async (templateId: string) => {
    const { data: user } = await supabase.auth.getUser();
    if (!user.user) {
      toast({
        title: "Error",
        description: "Please sign in to create a room",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      const room = await createNewRoom(templateId, user.user.id);
      toast({
        title: "Success",
        description: "Battle room created successfully!",
      });
      navigate(`/multiplayer/${room.id}`);
    } catch (error) {
      console.error('Error creating room:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create room",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const joinRoom = async (roomId: string) => {
    const { data: user } = await supabase.auth.getUser();
    if (!user.user) {
      toast({
        title: "Error",
        description: "Please sign in to join a room",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      await joinExistingRoom(roomId, user.user.id);
      navigate(`/multiplayer/${roomId}`);
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to join room",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const leaveCurrentRoom = async (roomId: string) => {
    if (!currentUser) return;

    setIsLoading(true);
    try {
      await leaveRoom(roomId, currentUser);
      toast({
        title: "Success",
        description: "Left the room successfully",
      });
      fetchRooms();
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to leave room",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const reenterRoom = (roomId: string) => {
    navigate(`/multiplayer/${roomId}`);
  };

  return {
    rooms,
    currentUser,
    isLoading,
    createRoom,
    joinRoom,
    leaveRoom: leaveCurrentRoom,
    reenterRoom,
    maxParticipants: MAX_PARTICIPANTS,
  };
};
