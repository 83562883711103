
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Eye, EyeOff } from "lucide-react";
import { useSearchParams } from "react-router-dom";

interface CredentialsFieldsProps {
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
}

export const CredentialsFields = ({
  email,
  setEmail,
  password,
  setPassword,
}: CredentialsFieldsProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('ref');

  return (
    <div className="space-y-4">
      <Input
        type="email"
        placeholder="Email *"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="bg-white/20 border-white/30 text-white placeholder:text-gray-400"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
        title="Please enter a valid email address"
      />
      <div className="relative">
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Password *"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          minLength={6}
          className="bg-white/20 border-white/30 text-white placeholder:text-gray-400"
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-white"
        >
          {showPassword ? (
            <EyeOff className="h-5 w-5" />
          ) : (
            <Eye className="h-5 w-5" />
          )}
        </button>
      </div>
      {referralCode && (
        <Input
          type="text"
          value={referralCode}
          readOnly
          className="bg-white/10 border-white/30 text-gray-400"
        />
      )}
    </div>
  );
};
