
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { HomeLink } from '@/components/HomeLink';
import { MultiplayerRoomCard } from './MultiplayerRoomCard';
import { useMultiplayerRooms } from '@/hooks/use-multiplayer-rooms';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { MultiplayerQuizTemplate } from '@/types/multiplayer';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock, Trophy, Users, Loader2 } from "lucide-react";
import { useToast } from '@/hooks/use-toast';

export const MultiplayerLobby = () => {
  const { toast } = useToast();
  const {
    rooms,
    currentUser,
    isLoading,
    createRoom,
    joinRoom,
    leaveRoom,
    reenterRoom,
    maxParticipants
  } = useMultiplayerRooms();

  // Fetch multiplayer quiz templates
  const { data: quizTemplates, isLoading: templatesLoading } = useQuery({
    queryKey: ['multiplayer-templates'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('multiplayer_quiz_templates')
        .select('*')
        .order('level', { ascending: true });

      if (error) throw error;
      return data as MultiplayerQuizTemplate[];
    },
  });

  const groupedTemplates = quizTemplates?.reduce((acc, template) => {
    if (!acc[template.level_type]) {
      acc[template.level_type] = [];
    }
    acc[template.level_type].push(template);
    return acc;
  }, {} as Record<string, MultiplayerQuizTemplate[]>);

  const handleCreateRoom = (templateId: string) => {
    const template = quizTemplates?.find(q => q.id === templateId);
    if (template) {
      createRoom(templateId);
    } else {
      toast({
        title: "Error",
        description: "Invalid quiz template selected",
        variant: "destructive",
      });
    }
  };

  const getLevelColor = (type: 'beginner' | 'intermediate' | 'expert') => {
    switch (type) {
      case 'beginner':
        return 'text-green-500';
      case 'intermediate':
        return 'text-yellow-500';
      case 'expert':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <HomeLink />
      <h2 className="text-2xl font-bold text-gameGold mb-4">Multiplayer Lobby</h2>

      <div className="bg-gamePurple/20 p-4 rounded-lg mb-8">
        <h3 className="text-3xl font-bold text-gameGold mb-3">Choose your battles wisely!</h3>
        <p className="text-gameGold text-lg">
          Select a quiz level you feel confident about and create a battle room. Each battle allows 2 warriors to compete.
        </p>
      </div>

      {templatesLoading ? (
        <div className="flex justify-center items-center py-12">
          <Loader2 className="h-8 w-8 animate-spin text-gameGold" />
          <span className="ml-2 text-gameGold">Loading quiz templates...</span>
        </div>
      ) : (
        <div className="space-y-8 mb-8">
          {['beginner', 'intermediate', 'expert'].map((level) => (
            <div key={level} className="space-y-4">
              <h3 className={`text-xl font-bold capitalize ${getLevelColor(level as 'beginner' | 'intermediate' | 'expert')}`}>
                {level} Level Quizzes
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {groupedTemplates?.[level]?.map((template) => (
                  <Card key={template.id} className="bg-card hover:bg-card/90 transition-colors">
                    <CardHeader>
                      <CardTitle>{template.title}</CardTitle>
                      <CardDescription>{template.description}</CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-sm text-gray-400">
                          <Clock className="h-4 w-4" />
                          <span>{template.time_limit} minutes</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-400">
                          <Trophy className="h-4 w-4" />
                          <span>Level {template.level}</span>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-gray-400">
                          <Users className="h-4 w-4" />
                          <span>{template.questions.length} questions</span>
                        </div>
                        <Button 
                          className="w-full mt-4"
                          onClick={() => handleCreateRoom(template.id)}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <>
                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                              Creating...
                            </>
                          ) : (
                            'Create Battle'
                          )}
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
                {!groupedTemplates?.[level]?.length && (
                  <p className="text-gray-400 col-span-full">No quizzes available for this level yet</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mt-8">
        <h3 className="text-xl font-bold text-gameGold mb-4">Active Battles</h3>
        {isLoading ? (
          <div className="flex justify-center items-center py-8">
            <Loader2 className="h-6 w-6 animate-spin text-gameGold" />
            <span className="ml-2 text-gameGold">Loading active battles...</span>
          </div>
        ) : (
          <div className="grid gap-4">
            {rooms.map((room) => (
              <MultiplayerRoomCard
                key={room.id}
                room={room}
                currentUser={currentUser}
                onJoin={joinRoom}
                onLeave={leaveRoom}
                onReenter={reenterRoom}
                maxParticipants={maxParticipants}
                isLoading={isLoading}
              />
            ))}
            {!rooms.length && (
              <p className="text-center text-gray-400">No active battles found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
