import React, { useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { HomeLink } from '@/components/HomeLink';

export const MultiplayerLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<any[]>([]);

  useEffect(() => {
    fetchLeaderboard();
    subscribeToLeaderboard();
  }, []);

  const fetchLeaderboard = async () => {
    const { data, error } = await supabase
      .from('multiplayer_leaderboard')
      .select(`
        *,
        profiles:user_id (
          username,
          display_name
        )
      `)
      .order('wins', { ascending: false })
      .limit(10);

    if (!error && data) {
      setLeaderboard(data);
    }
  };

  const subscribeToLeaderboard = () => {
    const channel = supabase
      .channel('leaderboard_updates')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'multiplayer_leaderboard' },
        () => {
          fetchLeaderboard();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <HomeLink />
      <h2 className="text-2xl font-bold text-gameGold mb-8">Multiplayer Leaderboard</h2>
      <div className="bg-gamePurple/30 rounded-lg overflow-hidden">
        <div className="grid grid-cols-4 gap-4 p-4 border-b border-gameGold/20 font-bold text-gameGold">
          <div>Player</div>
          <div>Wins</div>
          <div>Losses</div>
          <div>Points</div>
        </div>
        {leaderboard.map((entry) => (
          <div key={entry.id} className="grid grid-cols-4 gap-4 p-4 border-b border-gameGold/10">
            <div className="text-gameGold">
              {entry.profiles?.display_name || entry.profiles?.username || 'Unknown Player'}
            </div>
            <div className="text-green-500">{entry.wins}</div>
            <div className="text-red-500">{entry.losses}</div>
            <div className="text-gameGold">{entry.total_points}</div>
          </div>
        ))}
      </div>
    </div>
  );
};