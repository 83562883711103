
import { Button } from "@/components/ui/button";

interface RatingFormProps {
  comment: string;
  isSubmitting: boolean;
  onCommentChange: (comment: string) => void;
  onSubmit: () => void;
}

export const RatingForm = ({
  comment,
  isSubmitting,
  onCommentChange,
  onSubmit,
}: RatingFormProps) => {
  return (
    <>
      <textarea
        className="w-full max-w-md px-3 py-2 bg-white/10 border border-gray-600 rounded-md text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gameGold"
        placeholder="Share your feedback (optional)"
        value={comment}
        onChange={(e) => onCommentChange(e.target.value)}
        rows={2}
      />
      <Button
        onClick={onSubmit}
        disabled={isSubmitting}
        className="bg-gameGold hover:bg-gameGold/90 text-black"
      >
        {isSubmitting ? 'Submitting...' : 'Submit Rating'}
      </Button>
    </>
  );
};
