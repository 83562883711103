
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useUserStatus } from '@/hooks/use-user-status';
import { UserNavButtons } from './UserNavButtons';
import { CallToAction } from './CallToAction';

export const HeroBanner = () => {
  const {
    user,
    displayName,
    points,
    isAmbassador,
    referralCount,
    hasNewMessages
  } = useUserStatus();

  return (
    <div className="flex-1 flex flex-col items-center justify-center text-center px-4 py-16">
      {user && (
        <UserNavButtons
          points={points}
          hasNewMessages={hasNewMessages}
          isAmbassador={isAmbassador}
          referralCount={referralCount}
        />
      )}
      
      <h2 className="text-gameGold text-4xl font-bold mb-6">
        {user 
          ? `Welcome ${displayName}, Let the battle begin`
          : 'Let the battle begin'
        }
      </h2>
      
      <p className="text-gray-300 text-lg max-w-3xl mb-8">
        Test your combat skills, compete with others, and master the art of warfare through our engaging quest system.
      </p>
      
      <div className="flex flex-col gap-4 justify-center w-full max-w-[520px]">
        <CallToAction isAuthenticated={!!user} />
        <Link to="/learn-more" className="w-full">
          <Button variant="outline" className="w-full text-gameGold border-gameGold hover:bg-gameGold/10">
            Learn More
          </Button>
        </Link>
      </div>
    </div>
  );
};
