import { CsvUpload } from "@/components/quiz/CsvUpload";
import { Question } from "@/types/quiz";

interface MultiplayerCsvSectionProps {
  onQuestionsImported: (questions: Question[]) => void;
}

export const MultiplayerCsvSection = ({
  onQuestionsImported,
}: MultiplayerCsvSectionProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-white font-semibold">Import Questions from CSV</h3>
      <CsvUpload onQuestionsImported={onQuestionsImported} level={1} />
    </div>
  );
};