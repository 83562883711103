
import { useRef, useCallback } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { RealtimeChannel } from '@supabase/supabase-js';

export const useRoomPresence = (roomId: string | undefined, onPresenceChange: () => void) => {
  const presenceRef = useRef<{ [key: string]: any }>({});
  const channelRef = useRef<RealtimeChannel | null>(null);

  const handlePresenceSync = () => {
    if (!channelRef.current) return;
    const newState = channelRef.current.presenceState();
    console.log('Presence state synced:', newState);
    presenceRef.current = newState;
  };

  const handlePresenceJoin = ({ key, newPresences }: { key: string, newPresences: any[] }) => {
    console.log('Player joined:', key, newPresences);
    onPresenceChange();
  };

  const handlePresenceLeave = ({ key, leftPresences }: { key: string, leftPresences: any[] }) => {
    console.log('Player left:', key, leftPresences);
    onPresenceChange();
  };

  const trackPresence = useCallback(async (channel: RealtimeChannel) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user && roomId) {
      const presenceData = {
        user_id: user.id,
        online_at: new Date().toISOString(),
        room_id: roomId
      };
      await channel.track(presenceData);
    }
  }, [roomId]);

  return {
    presenceRef,
    channelRef,
    handlePresenceSync,
    handlePresenceJoin,
    handlePresenceLeave,
    trackPresence
  };
};
