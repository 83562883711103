import { useParams } from 'react-router-dom';
import { useRoomState } from './multiplayer/use-room-state';
import { useParticipants } from './multiplayer/use-participants';
import { useGameState } from './multiplayer/use-game-state';
import { useEffect, useCallback, useState, useRef } from 'react';
import { useToast } from '@/hooks/use-toast';

export const useMultiplayerGame = () => {
  const { roomId } = useParams();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const initRef = useRef(false);
  
  const { room, fetchRoomData, startGame, subscribeToRoom } = useRoomState(roomId);
  const { participants, setParticipants, isReady, setPlayerReady, leaveGame } = useParticipants(roomId);
  const { currentQuestion, selectedAnswer, setSelectedAnswer, submitAnswer, handleGameEnd } = useGameState(roomId);

  const initializeGame = useCallback(async () => {
    if (!roomId || initRef.current) return;
    
    console.log('Initializing game...');
    initRef.current = true;
    setIsLoading(true);
    
    try {
      const fetchedRoom = await fetchRoomData();
      if (fetchedRoom) {
        // Ensure participants data is available
        if (fetchedRoom.multiplayer_participants) {
          setParticipants(fetchedRoom.multiplayer_participants);
        }

        // Check if game should start (all players ready)
        if (fetchedRoom.status === 'waiting' && 
            fetchedRoom.multiplayer_participants &&
            fetchedRoom.multiplayer_participants.length >= 2 && 
            fetchedRoom.multiplayer_participants.every((p: any) => p.is_ready)) {
          await startGame();
          // Refetch after starting to get updated status
          await fetchRoomData();
        }
      }
    } catch (error) {
      console.error('Error initializing game:', error);
      toast({
        title: "Error",
        description: "Failed to initialize game. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [roomId, fetchRoomData, setParticipants, startGame, toast]);

  useEffect(() => {
    console.log('Setting up game subscriptions, should only run once');
    if (!roomId) return;
    
    initializeGame();
    const channel = subscribeToRoom();
    
    return () => {
      if (channel) {
        console.log('Cleaning up game subscription');
        channel.unsubscribe();
      }
      initRef.current = false;
    };
  }, [roomId, initializeGame, subscribeToRoom]);

  useEffect(() => {
    if (room?.multiplayer_participants) {
      console.log('Updating participants from room data');
      setParticipants(room.multiplayer_participants);
    }
  }, [room?.multiplayer_participants, setParticipants]);

  return {
    room,
    participants,
    currentQuestion,
    selectedAnswer,
    isReady,
    isLoading,
    setSelectedAnswer,
    setPlayerReady,
    submitAnswer: () => {
      if (!room || !room.quiz_data || !room.quiz_data.questions) {
        toast({
          title: "Error",
          description: "Cannot submit answer: Quiz data is missing",
          variant: "destructive",
        });
        return;
      }
      
      // Validate current question exists
      const question = room.quiz_data.questions[currentQuestion];
      if (!question) {
        toast({
          title: "Error", 
          description: "Cannot submit answer: Question not found",
          variant: "destructive"
        });
        return;
      }
      
      return submitAnswer(participants, room.quiz_data);
    },
    handleGameEnd: () => handleGameEnd(participants),
    fetchRoomData,
    leaveGame
  };
};
