
import { QuizList } from "@/components/admin/quiz/QuizList";
import { LevelManager } from "@/components/admin/LevelManager";
import { MultiplayerMonitoring } from "@/components/admin/multiplayer/MultiplayerMonitoring";
import { AdSlotManager } from "@/components/admin/AdSlotManager";
import { AmbassadorManagement } from "@/components/admin/AmbassadorManagement";
import { MessagingTab } from "@/components/admin/messages/MessagingTab";
import { RatingManagement } from "@/components/admin/RatingManagement";
import { useQuizManagement } from "@/hooks/use-quiz-management";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MultiplayerQuizList } from "@/components/admin/multiplayer/MultiplayerQuizList";
import { UserManagement } from "@/components/admin/UserManagement";

export const AdminContent = ({ activeTab }: { activeTab: string }) => {
  const {
    quizzes,
    isLoading,
    error,
    handleEdit,
    handleDelete,
    handleQuizSave,
  } = useQuizManagement();

  switch (activeTab) {
    case 'users':
      return <UserManagement />;
    case 'quizzes':
      return (
        <QuizList
          quizzes={quizzes}
          isLoading={isLoading}
          error={error}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onCreateNew={handleQuizSave}
        />
      );
    case 'levels':
      return <LevelManager />;
    case 'multiplayer':
      return (
        <Tabs defaultValue="templates" className="w-full">
          <TabsList>
            <TabsTrigger value="templates">Quiz Templates</TabsTrigger>
            <TabsTrigger value="monitoring">Monitoring</TabsTrigger>
          </TabsList>
          <TabsContent value="templates" className="mt-4">
            <MultiplayerQuizList />
          </TabsContent>
          <TabsContent value="monitoring" className="mt-4">
            <MultiplayerMonitoring />
          </TabsContent>
        </Tabs>
      );
    case 'ads':
      return <AdSlotManager />;
    case 'ambassadors':
      return <AmbassadorManagement />;
    case 'messages':
      return <MessagingTab />;
    case 'ratings':
      return <RatingManagement />;
    default:
      return null;
  }
};
