
import { supabase } from '@/integrations/supabase/client';
import { MultiplayerRoom } from '@/types/multiplayer-room';
import { transformRoomData, createFallbackRoom } from '../utils/transform-quiz-data';

/**
 * Fetches room data from Supabase
 */
export const fetchRoomFromDatabase = async (roomId: string) => {
  const { data: roomData, error: roomError } = await supabase
    .from('multiplayer_rooms')
    .select('*, multiplayer_participants(*, profiles(*))')
    .eq('id', roomId)
    .single();

  if (roomError) {
    console.error('Error fetching room:', roomError);
    throw roomError;
  }
  
  if (!roomData) {
    console.error('Room not found');
    throw new Error('Room not found');
  }

  console.log('Room data fetched:', roomData);
  return roomData;
};

/**
 * Updates room status to start the game
 */
export const startGameInDatabase = async (roomId: string) => {
  const { error } = await supabase
    .from('multiplayer_rooms')
    .update({
      status: 'in_progress',
      started_at: new Date().toISOString()
    })
    .eq('id', roomId);

  if (error) throw error;
  console.log('Game started successfully');
};

/**
 * Process and transform the raw room data into a valid MultiplayerRoom
 */
export const processRoomData = (roomData: any): MultiplayerRoom => {
  try {
    return transformRoomData(roomData);
  } catch (transformError) {
    console.error('Error transforming room data:', transformError);
    return createFallbackRoom(roomData);
  }
};
