
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Quiz, Question } from "@/types/quiz";
import { useToast } from "@/hooks/use-toast";

export const useQuizManagement = () => {
  const [editingQuiz, setEditingQuiz] = useState<Quiz | null>(null);
  const { toast } = useToast();

  const {
    data: quizzes,
    refetch,
    isLoading,
    error
  } = useQuery({
    queryKey: ["quizzes"],
    queryFn: async () => {
      console.log("Fetching quizzes...");
      const { data, error } = await supabase
        .from("quizzes")
        .select("*, questions(*)")
        .order('created_at', { ascending: false });

      if (error) {
        console.error("Error fetching quizzes:", error);
        throw error;
      }
      
      const transformedData = data?.map((quiz: any) => ({
        ...quiz,
        questions: quiz.questions?.map((q: any) => ({
          ...q,
          options: Array.isArray(q.options) ? q.options : JSON.parse(q.options || '[]')
        })) as Question[]
      })) as Quiz[];

      console.log("Fetched quizzes:", transformedData);
      return transformedData;
    },
  });

  const handleEdit = async (quiz: Quiz) => {
    console.log("Editing quiz:", quiz);
    try {
      setEditingQuiz(quiz);
      toast({
        title: "Edit Mode",
        description: "Now editing quiz: " + quiz.title,
      });
    } catch (error) {
      console.error("Error setting up edit mode:", error);
      toast({
        title: "Error",
        description: "Failed to enter edit mode",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (quiz: Quiz) => {
    try {
      const { error } = await supabase
        .from("quizzes")
        .delete()
        .eq("id", quiz.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Quiz deleted successfully",
      });
      refetch();
    } catch (error) {
      console.error("Error deleting quiz:", error);
      toast({
        title: "Error",
        description: "Failed to delete quiz",
        variant: "destructive",
      });
    }
  };

  const handleQuizSave = async () => {
    setEditingQuiz(null);
    refetch();
  };

  return {
    quizzes,
    isLoading,
    error,
    editingQuiz,
    setEditingQuiz,
    handleEdit,
    handleDelete,
    handleQuizSave,
  };
};
