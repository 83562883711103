
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";

interface MultiplayerFormFieldsProps {
  title: string;
  description: string;
  timeLimit: string;
  level: number;
  levelType: 'beginner' | 'intermediate' | 'expert';
  onTitleChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onTimeLimitChange: (value: string) => void;
  onLevelChange: (value: number) => void;
  onLevelTypeChange: (value: 'beginner' | 'intermediate' | 'expert') => void;
}

export const MultiplayerFormFields = ({
  title,
  description,
  timeLimit,
  level,
  levelType,
  onTitleChange,
  onDescriptionChange,
  onTimeLimitChange,
  onLevelChange,
  onLevelTypeChange,
}: MultiplayerFormFieldsProps) => {
  return (
    <div className="space-y-6">
      <Input
        placeholder="Quiz Title"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        required
        className="w-full bg-transparent border-gray-700 text-white"
      />

      <Textarea
        placeholder="Quiz Description"
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
        className="min-h-[100px] bg-transparent border-gray-700 text-white"
      />

      <div className="space-y-2">
        <Label>Time Limit (Minutes)</Label>
        <Input
          type="number"
          placeholder="Time Limit in Minutes"
          value={timeLimit}
          onChange={(e) => onTimeLimitChange(e.target.value)}
          min="1"
          required
          className="w-full bg-transparent border-gray-700 text-white"
        />
      </div>

      <div className="space-y-2">
        <Label>Quiz Level</Label>
        <Input
          type="number"
          value={level}
          onChange={(e) => onLevelChange(parseInt(e.target.value))}
          min="1"
          required
          className="w-full bg-transparent border-gray-700 text-white"
        />
      </div>

      <div className="space-y-2">
        <Label>Difficulty Level</Label>
        <Select
          value={levelType}
          onValueChange={(value: 'beginner' | 'intermediate' | 'expert') => onLevelTypeChange(value)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select difficulty level" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="beginner">Beginner</SelectItem>
            <SelectItem value="intermediate">Intermediate</SelectItem>
            <SelectItem value="expert">Expert</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
