
import React from 'react';
import { HomeLink } from '@/components/HomeLink';
import { Button } from '@/components/ui/button';
import { MultiplayerParticipant } from '@/types/multiplayer-room';
import { useNavigate } from 'react-router-dom';

interface MultiplayerWaitingRoomProps {
  participants: MultiplayerParticipant[];
  isReady: boolean;
  onReady: () => void;
  quizTitle?: string;
}

export const MultiplayerWaitingRoom = ({ 
  participants, 
  isReady, 
  onReady,
  quizTitle 
}: MultiplayerWaitingRoomProps) => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-8">
      <HomeLink />
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gameGold">Waiting Room</h2>
        <Button 
          variant="outline"
          onClick={() => navigate('/multiplayer')}
        >
          Return to Lobby
        </Button>
      </div>
      {quizTitle && (
        <p className="text-lg text-gray-300 mb-6">Quiz: {quizTitle}</p>
      )}
      <div className="bg-gamePurple/30 p-6 rounded-lg">
        <div className="mb-6">
          <h3 className="text-xl text-gameGold mb-4">Players:</h3>
          {participants.map((participant) => (
            <div key={participant.user_id} className="flex items-center gap-4 mb-2">
              <span className="text-gameGold">
                {participant.profiles?.display_name || 'Unknown Player'}
              </span>
              {participant.is_ready && (
                <span className="text-green-500">Ready</span>
              )}
            </div>
          ))}
        </div>
        {!isReady && (
          <Button onClick={onReady}>Ready</Button>
        )}
      </div>
    </div>
  );
};
