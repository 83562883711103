
import { Table, TableBody } from "@/components/ui/table";
import { type Profile } from "../types";
import { UserTableRow } from "./UserTableRow";

interface UserTableProps {
  profiles: Profile[];
  onToggleAdmin?: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onToggleRestriction?: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onDeleteUser?: (profileId: string) => Promise<void>;
  onMessageUser?: (profile: Profile) => void;
  showMessageAction?: boolean;
}

export const UserTable = ({ 
  profiles, 
  onToggleAdmin = async () => {}, 
  onToggleRestriction = async () => {}, 
  onDeleteUser = async () => {},
  onMessageUser,
  showMessageAction = false
}: UserTableProps) => {
  return (
    <Table>
      <TableBody>
        {profiles.map((profile) => (
          <UserTableRow 
            key={profile.id} 
            profile={profile}
            onToggleAdmin={onToggleAdmin}
            onToggleRestriction={onToggleRestriction}
            onDeleteUser={onDeleteUser}
            onMessageUser={onMessageUser}
            showMessageAction={showMessageAction}
          />
        ))}
      </TableBody>
    </Table>
  );
};

