
import React, { useState } from "react";
import { useGame } from "@/hooks/use-game";
import { HomeNavigation } from "@/components/home/HomeNavigation";
import { HeroBanner } from "@/components/home/HeroBanner";
import { FeatureCards } from "@/components/home/FeatureCards";
import { HomeFooter } from "@/components/home/HomeFooter";
import { GameContent } from "@/components/home/GameContent";
import { AdsenseScript } from "@/components/ads/AdsenseScript";
import { AdUnit } from "@/components/ads/AdUnit";
import { useIsMobile } from "@/hooks/use-is-mobile";

const Index = () => {
  const { gameStarted, userId, handleLogout } = useGame();
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const { isMobile } = useIsMobile();

  const handleLevelSelect = (level: number) => {
    setSelectedLevel(level);
  };

  const handleBackToLevels = () => {
    setSelectedLevel(null);
  };

  if (!gameStarted) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gamePurple via-[#2D1B4D] to-gameSlate">
        <AdsenseScript />
        <div className="bg-gamePurple/30 py-2 text-center">
          <p className="text-gameGold text-sm">"...the sword of the Spirit, which is the word of God." Ephesians 6:17</p>
        </div>

        {/* Top banner ad */}
        <AdUnit 
          slot="top-banner" 
          format="horizontal"
          className="mx-auto max-w-[728px] my-4 px-4"
        />

        <HomeNavigation />
        <HeroBanner />
        
        {/* Ad between hero and features */}
        <AdUnit 
          slot="hero-feature"
          format={isMobile ? 'rectangle' : 'horizontal'}
          className="mx-auto max-w-[728px] my-8 px-4"
        />

        <FeatureCards />
        
        {/* Ad above footer */}
        <AdUnit 
          slot="pre-footer"
          format={isMobile ? 'rectangle' : 'horizontal'}
          className="mx-auto max-w-[728px] my-8 px-4"
        />

        <HomeFooter />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gamePurple via-[#2D1B4B] to-gameSlate">
      <AdsenseScript />
      <GameContent
        userId={userId}
        handleLogout={handleLogout}
        selectedLevel={selectedLevel}
        handleLevelSelect={handleLevelSelect}
        handleBackToLevels={handleBackToLevels}
      />
      
      {/* Game content ad */}
      {!selectedLevel && (
        <AdUnit 
          slot="game-content"
          format={isMobile ? 'rectangle' : 'horizontal'}
          className="mx-auto max-w-[728px] my-8 px-4"
        />
      )}
    </div>
  );
};

export default Index;
