
import { Award } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

interface BecomeAmbassadorButtonProps {
  referralCount?: number;
}

export const BecomeAmbassadorButton = ({ referralCount }: BecomeAmbassadorButtonProps) => {
  const buttonText = referralCount && referralCount > 0 
    ? `Claim Ambassador Rank (${referralCount} Referrals)`
    : "Become an Ambassador";

  return (
    <Link to="/referrals">
      <Button 
        variant="outline" 
        className="w-full text-gameGold border-gameGold hover:bg-gameGold/10 flex items-center justify-center gap-2"
      >
        <Award className="w-4 h-4" />
        {buttonText}
      </Button>
    </Link>
  );
};
