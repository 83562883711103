
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { MultiplayerRoom } from '@/types/multiplayer-room';
import { 
  fetchRoomFromDatabase, 
  startGameInDatabase,
  processRoomData
} from './services/room-data-service';

export const useRoomData = (roomId: string | undefined) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [room, setRoom] = useState<MultiplayerRoom | null>(null);

  const fetchRoomData = useCallback(async () => {
    if (!roomId) return null;

    try {
      const roomData = await fetchRoomFromDatabase(roomId);
      
      if (roomData.status === 'completed') {
        toast({
          title: "Game Over",
          description: "This game has ended. Returning to lobby.",
        });
        navigate('/multiplayer');
        return null;
      }

      try {
        const transformedRoom = processRoomData(roomData);
        setRoom(transformedRoom);
        return transformedRoom;
      } catch (transformError) {
        console.error('Error processing room data:', transformError);
        toast({
          title: "Warning",
          description: "Some quiz data may be missing or invalid. Using fallback questions.",
        });
        return null;
      }
    } catch (error) {
      console.error('Error fetching room data:', error);
      toast({
        title: "Error",
        description: "Failed to fetch room data. Please try again.",
        variant: "destructive",
      });
      navigate('/multiplayer');
      return null;
    }
  }, [roomId, navigate, toast]);

  const startGame = useCallback(async () => {
    if (!roomId) return;

    try {
      await startGameInDatabase(roomId);
    } catch (error) {
      console.error('Error starting game:', error);
      toast({
        title: "Error",
        description: "Failed to start game. Please try again.",
        variant: "destructive",
      });
    }
  }, [roomId, toast]);

  return {
    room,
    setRoom,
    fetchRoomData,
    startGame,
  };
};
