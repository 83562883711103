import React from 'react';
import { Button } from '@/components/ui/button';
import { ShareButton } from './ShareButton';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Trophy, AlertCircle } from 'lucide-react';

interface QuizCompleteProps {
  score: number;
  totalQuestions: number;
  level: number;
  onBack: () => void;
}

export const QuizComplete = ({ score, totalQuestions, level, onBack }: QuizCompleteProps) => {
  const isPerfectScore = score === totalQuestions;
  const percentage = (score / totalQuestions) * 100;
  
  return (
    <div className="space-y-6 text-center">
      <h3 className="text-2xl font-bold">Quiz Complete!</h3>
      
      <div className="p-6 bg-gameSlate/10 rounded-lg">
        <p className="text-4xl font-bold text-gameGold mb-2">
          {score}/{totalQuestions}
        </p>
        {isPerfectScore ? (
          <Alert className="bg-green-500/10 border-green-500">
            <Trophy className="h-5 w-5 text-green-500" />
            <AlertDescription className="ml-2">
              Perfect score! Points have been awarded! 🎉
            </AlertDescription>
          </Alert>
        ) : (
          <Alert className="bg-yellow-500/10 border-yellow-500">
            <AlertCircle className="h-5 w-5 text-yellow-500" />
            <AlertDescription className="ml-2">
              You need a perfect score to earn points. Try again! 📚
            </AlertDescription>
          </Alert>
        )}
      </div>

      {isPerfectScore && (
        <ShareButton 
          score={score}
          totalQuestions={totalQuestions}
          level={level}
        />
      )}

      <Button 
        onClick={onBack} 
        variant="outline" 
        className="w-full"
      >
        Back to Levels
      </Button>
    </div>
  );
};