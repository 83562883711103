
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Award, Mail } from 'lucide-react';

interface UserNavButtonsProps {
  points: number;
  hasNewMessages: boolean;
  isAmbassador: boolean;
  referralCount: number;
}

export const UserNavButtons = ({ 
  points, 
  hasNewMessages, 
  isAmbassador, 
  referralCount 
}: UserNavButtonsProps) => {
  return (
    <div className="flex flex-col md:grid md:grid-cols-3 gap-4 max-w-3xl mx-auto mb-8">
      <Link to="/profile">
        <Button variant="outline" className="w-full text-gameGold border-gameGold hover:bg-gameGold/10">
          Profile ({points} points)
        </Button>
      </Link>
      <Link to="/messages">
        <Button 
          variant="outline" 
          className="w-full text-gameGold border-gameGold hover:bg-gameGold/10 flex items-center justify-center gap-2 relative"
        >
          <Mail className="w-4 h-4" />
          Notifications
          {hasNewMessages && (
            <span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full" />
          )}
        </Button>
      </Link>
      {isAmbassador ? (
        <Link to="/referrals">
          <Button 
            variant="outline" 
            className="w-full text-gameGold border-gameGold hover:bg-gameGold/10 flex items-center justify-center gap-2"
          >
            <Award className="w-4 h-4" />
            Ambassador Rank ({referralCount})
          </Button>
        </Link>
      ) : (
        <Link to="/referrals">
          <Button 
            variant="outline" 
            className="w-full text-gameGold border-gameGold hover:bg-gameGold/10 flex items-center justify-center gap-2"
          >
            <Award className="w-4 h-4" />
            Become an Ambassador
          </Button>
        </Link>
      )}
    </div>
  );
};

