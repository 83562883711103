import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface Profile {
  username: string;
  display_name: string;
  country: string | null;
}

export const useProfileForm = (profile: Profile, userId: string, onSuccess: () => void) => {
  const [username, setUsername] = useState(profile.username || "");
  const [displayName, setDisplayName] = useState(profile.display_name || "");
  const [country, setCountry] = useState(profile.country || "");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  console.log("Form state initialized with:", {
    username,
    displayName,
    country,
    timestamp: new Date().toISOString(),
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const updateData = {
      username,
      display_name: displayName,
      country,
      updated_at: new Date().toISOString(),
    };

    console.log("Attempting profile update with data:", {
      updateData,
      userId,
      timestamp: new Date().toISOString(),
    });

    try {
      const { data, error } = await supabase
        .from("profiles")
        .update(updateData)
        .eq("id", userId)
        .select();

      console.log("Supabase update response:", {
        data,
        error,
        timestamp: new Date().toISOString(),
      });

      if (error) {
        console.error("Profile update error:", {
          error,
          timestamp: new Date().toISOString(),
        });
        throw error;
      }

      console.log("Profile updated successfully:", {
        data,
        timestamp: new Date().toISOString(),
      });

      toast({
        title: "Profile updated successfully",
        description: "Your profile has been updated.",
      });
      onSuccess();
    } catch (error) {
      console.error("Error updating profile:", {
        error,
        timestamp: new Date().toISOString(),
      });
      toast({
        title: "Error updating profile",
        description: "There was an error updating your profile. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    username,
    setUsername,
    displayName,
    setDisplayName,
    country,
    setCountry,
    isLoading,
    handleSubmit,
  };
};