
import { HomeLink } from "@/components/HomeLink";
import { AuthForm } from "@/components/AuthForm";
import { GameLogo } from "@/components/GameLogo";
import { useSearchParams, Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

const Auth = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  
  // Check if user is already authenticated
  const { data, isLoading } = useQuery({
    queryKey: ['session'],
    queryFn: async () => {
      const { data } = await supabase.auth.getSession();
      return data.session;
    },
  });

  // If authenticated, redirect to home
  if (!isLoading && data) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gamePurple to-gameSlate flex flex-col items-center justify-center p-4 relative">
      <HomeLink />
      <div className="w-full max-w-md mx-auto">
        <GameLogo className="mb-8" />
        <AuthForm initialMode={mode === 'signup'} />
      </div>
    </div>
  );
};

export default Auth;
