
import About from '@/pages/About';
import Auth from '@/pages/Auth';
import FAQ from '@/pages/FAQ';
import LeaderboardPage from '@/pages/LeaderboardPage';
import LearnMore from '@/pages/LearnMore';
import Privacy from '@/pages/Privacy';
import Support from '@/pages/Support';
import Terms from '@/pages/Terms';
import StudyGuide from '@/pages/StudyGuide';
import { Navigate } from 'react-router-dom';
import { MultiplayerLeaderboard } from '@/components/multiplayer/MultiplayerLeaderboard';
import Leaderboards from '@/pages/Leaderboards';

export const publicRoutes = [
  { path: "/", element: <Navigate to="/home" replace /> },
  { path: "/auth", element: <Auth /> },
  { path: "/about", element: <About /> },
  { path: "/faq", element: <FAQ /> },
  { path: "/learn-more", element: <LearnMore /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/terms", element: <Terms /> },
  { path: "/support", element: <Support /> },
  { path: "/study-guide", element: <StudyGuide /> },
  { path: "/leaderboard", element: <LeaderboardPage /> },
  { path: "/multiplayer-leaderboard", element: <MultiplayerLeaderboard /> },
  { path: "/leaderboards", element: <Leaderboards /> },
];
