
import React from 'react';
import { supabase } from '@/integrations/supabase/client';

export const useUserStatus = () => {
  const [user, setUser] = React.useState<any>(null);
  const [displayName, setDisplayName] = React.useState<string>('');
  const [points, setPoints] = React.useState<number>(0);
  const [isAmbassador, setIsAmbassador] = React.useState<boolean>(false);
  const [referralCount, setReferralCount] = React.useState<number>(0);
  const [hasNewMessages, setHasNewMessages] = React.useState<boolean>(false);

  const fetchReferralCount = async (userId: string) => {
    try {
      const { count } = await supabase
        .from('referrals')
        .select('*', { count: 'exact', head: true })
        .eq('referrer_id', userId);
      
      setReferralCount(count || 0);
    } catch (error) {
      console.error('Error fetching referral count:', error);
    }
  };

  const checkUnreadMessages = async (userId: string) => {
    try {
      const { count } = await supabase
        .from('message_read_status')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', userId)
        .eq('is_read', false);
      
      setHasNewMessages(count !== null && count > 0);
    } catch (error) {
      console.error('Error checking unread messages:', error);
    }
  };

  React.useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session?.user) {
        setUser(session.user);
        // Fetch user's profile
        const { data: profile } = await supabase
          .from('profiles')
          .select('display_name, points, is_ambassador')
          .eq('id', session.user.id)
          .single();
        
        if (profile) {
          setDisplayName(profile.display_name);
          setPoints(profile.points || 0);
          setIsAmbassador(profile.is_ambassador || false);
          
          // Initial referral count fetch
          await fetchReferralCount(session.user.id);
          // Initial unread messages check
          await checkUnreadMessages(session.user.id);
          
          // Set up real-time subscription for referrals
          const referralChannel = supabase
            .channel('referrals-changes')
            .on(
              'postgres_changes',
              {
                event: '*',
                schema: 'public',
                table: 'referrals',
                filter: `referrer_id=eq.${session.user.id}`
              },
              async () => {
                await fetchReferralCount(session.user.id);
              }
            )
            .subscribe();

          // Set up real-time subscription for messages
          const messageChannel = supabase
            .channel('message-changes')
            .on(
              'postgres_changes',
              {
                event: '*',
                schema: 'public',
                table: 'message_read_status',
                filter: `user_id=eq.${session.user.id}`
              },
              async () => {
                await checkUnreadMessages(session.user.id);
              }
            )
            .subscribe();

          return () => {
            supabase.removeChannel(referralChannel);
            supabase.removeChannel(messageChannel);
          };
        }
      } else {
        setUser(null);
        setDisplayName('');
        setPoints(0);
        setIsAmbassador(false);
        setReferralCount(0);
        setHasNewMessages(false);
      }
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session?.user) {
        setUser(session.user);
        // Fetch user's profile
        const { data: profile } = await supabase
          .from('profiles')
          .select('display_name, points, is_ambassador')
          .eq('id', session.user.id)
          .single();
        
        if (profile) {
          setDisplayName(profile.display_name);
          setPoints(profile.points || 0);
          setIsAmbassador(profile.is_ambassador || false);
          
          // Fetch referral count
          await fetchReferralCount(session.user.id);
          // Check unread messages
          await checkUnreadMessages(session.user.id);
        }
      } else {
        setUser(null);
        setDisplayName('');
        setPoints(0);
        setIsAmbassador(false);
        setReferralCount(0);
        setHasNewMessages(false);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return {
    user,
    displayName,
    points,
    isAmbassador,
    referralCount,
    hasNewMessages
  };
};
