import { Button } from "@/components/ui/button";

interface FormActionsProps {
  isLoading: boolean;
  onCancel: () => void;
}

export const FormActions = ({ isLoading, onCancel }: FormActionsProps) => {
  return (
    <div className="flex justify-end space-x-2 pt-4">
      <Button
        type="button"
        variant="outline"
        onClick={onCancel}
        className="bg-white/20 hover:bg-white/30 text-white border-white/30"
      >
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={isLoading}
        className="bg-gameGold hover:bg-gameGold/90 text-gamePurple"
      >
        {isLoading ? "Saving..." : "Save Changes"}
      </Button>
    </div>
  );
};