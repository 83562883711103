
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { type Profile } from "./types";
import { UserTable } from "./user/UserTable";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";

export const UserManagement = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    console.log("Fetching profiles...");
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error("Error fetching profiles:", error);
      toast({
        title: "Error",
        description: "Failed to fetch profiles",
        variant: "destructive",
      });
      return;
    }

    console.log("Fetched profiles:", data);
    setProfiles(data || []);
    setLoading(false);
  };

  const toggleAdminStatus = async (profileId: string, currentStatus: boolean | null) => {
    console.log("Toggling admin status for profile:", profileId, "Current status:", currentStatus);
    
    const { data, error } = await supabase
      .from('profiles')
      .update({ is_admin: !currentStatus })
      .eq('id', profileId)
      .select();

    if (error) {
      console.error("Error updating admin status:", error);
      toast({
        title: "Error",
        description: "Failed to update admin status",
        variant: "destructive",
      });
      return;
    }

    console.log("Admin status update response:", data);
    toast({
      title: "Success",
      description: "Admin status updated successfully",
    });
    fetchProfiles();
  };

  const toggleAccessRestriction = async (profileId: string, currentStatus: boolean | null) => {
    console.log("Toggling access restriction for profile:", profileId, "Current status:", currentStatus);
    
    const { data, error } = await supabase
      .from('profiles')
      .update({ is_restricted: !currentStatus })
      .eq('id', profileId)
      .select();

    if (error) {
      console.error("Error updating restriction status:", error);
      toast({
        title: "Error",
        description: "Failed to update access restriction",
        variant: "destructive",
      });
      return;
    }

    console.log("Access restriction update response:", data);
    toast({
      title: "Success",
      description: `User access ${!currentStatus ? 'restricted' : 'restored'} successfully`,
    });
    fetchProfiles();
  };

  const deleteUser = async (profileId: string) => {
    console.log("Deleting user:", profileId);
    
    const { error } = await supabase
      .from('profiles')
      .delete()
      .eq('id', profileId);

    if (error) {
      console.error("Error deleting user:", error);
      toast({
        title: "Error",
        description: "Failed to delete user",
        variant: "destructive",
      });
      return;
    }

    toast({
      title: "Success",
      description: "User deleted successfully",
    });
    fetchProfiles();
  };

  const filteredProfiles = profiles.filter((profile) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      profile.display_name?.toLowerCase().includes(searchLower) ||
      profile.username?.toLowerCase().includes(searchLower) ||
      profile.first_name?.toLowerCase().includes(searchLower) ||
      profile.last_name?.toLowerCase().includes(searchLower) ||
      profile.country?.toLowerCase().includes(searchLower)
    );
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          type="text"
          placeholder="Search users by name, username, or country..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10"
        />
      </div>
      <div className="rounded-md border">
        <UserTable 
          profiles={filteredProfiles}
          onToggleAdmin={toggleAdminStatus}
          onToggleRestriction={toggleAccessRestriction}
          onDeleteUser={deleteUser}
        />
      </div>
    </div>
  );
};
