
import { Link } from 'react-router-dom';

interface UserMenuItemsProps {
  userPoints: number;
  isAdmin: boolean;
  onCloseMenu?: () => void;
}

export const UserMenuItems = ({ userPoints, isAdmin, onCloseMenu }: UserMenuItemsProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <Link
          to="/multiplayer"
          className="text-gameGold hover:text-gameGold/80 py-2"
          onClick={onCloseMenu}
        >
          Multiplayer
        </Link>
        {isAdmin && (
          <Link
            to="/admin"
            className="text-gameGold hover:text-gameGold/80 py-2"
            onClick={onCloseMenu}
          >
            Admin
          </Link>
        )}
      </div>
    </>
  );
};

