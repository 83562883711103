
import { Button } from "@/components/ui/button";
import { AdminNavigation } from "./navigation/AdminNavigation";
import { HomeLink } from "@/components/HomeLink";
import { Menu } from "lucide-react";
import { useState } from "react";
import { cn } from "@/lib/utils";

interface AdminHeaderProps {
  activeTab: 'users' | 'quizzes' | 'levels' | 'multiplayer' | 'ads' | 'ambassadors' | 'messages' | 'ratings';
  setActiveTab: (tab: 'users' | 'quizzes' | 'levels' | 'multiplayer' | 'ads' | 'ambassadors' | 'messages' | 'ratings') => void;
  onLogout: () => void;
}

export const AdminHeader = ({ activeTab, setActiveTab, onLogout }: AdminHeaderProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row gap-4 justify-between items-center">
        <div className="flex flex-row w-full sm:w-auto gap-4 justify-between items-center">
          <HomeLink />
          <div className="flex gap-2 sm:hidden">
            <Button 
              variant="destructive" 
              onClick={onLogout}
            >
              Logout
            </Button>
            <Button
              variant="outline"
              onClick={toggleMobileMenu}
              className="p-2"
            >
              <Menu className="h-5 w-5" />
            </Button>
          </div>
        </div>
        <Button 
          variant="destructive" 
          onClick={onLogout}
          className="hidden sm:inline-flex"
        >
          Logout
        </Button>
      </div>
      <div>
        <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
        <div className={cn(
          "sm:block",
          isMobileMenuOpen ? "block bg-[#FEF7CD] p-4 rounded-lg" : "hidden"
        )}>
          <AdminNavigation activeTab={activeTab} onTabChange={setActiveTab} />
        </div>
      </div>
    </div>
  );
};
