import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { FormFields } from "./profile/FormFields";
import { FormActions } from "./profile/FormActions";
import { useProfileForm } from "@/hooks/use-profile-form";

interface EditProfileFormProps {
  profile: {
    username: string;
    display_name: string;
    country: string | null;
  };
  userId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export const EditProfileForm = ({
  profile,
  userId,
  onSuccess,
  onCancel,
}: EditProfileFormProps) => {
  console.log("EditProfileForm mounted with profile:", {
    profile,
    userId,
    timestamp: new Date().toISOString(),
  });

  const {
    username,
    setUsername,
    displayName,
    setDisplayName,
    country,
    setCountry,
    isLoading,
    handleSubmit,
  } = useProfileForm(profile, userId, onSuccess);

  return (
    <Card className="p-6">
      <CardHeader>
        <CardTitle>Edit Profile</CardTitle>
        <CardDescription>Update your profile information</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FormFields
            username={username}
            setUsername={setUsername}
            displayName={displayName}
            setDisplayName={setDisplayName}
            country={country}
            setCountry={setCountry}
          />
          <FormActions isLoading={isLoading} onCancel={onCancel} />
        </form>
      </CardContent>
    </Card>
  );
};