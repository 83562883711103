import { MultiplayerRoom } from '@/types/multiplayer-room';

/**
 * Transforms and validates the quiz data from the server response
 */
export const transformRoomData = (roomData: any): MultiplayerRoom => {
  if (!roomData) throw new Error('No room data received');
  
  try {
    console.log('Raw quiz data:', roomData.quiz_data);
    
    // Start with a default structure to ensure we have valid quiz data
    let parsedQuizData = {
      questions: [],
      title: roomData.quiz_title || "Quiz",
      description: "",
      timeLimit: 60
    };
    
    // If quiz_data is an array, treat it as an array of questions
    if (Array.isArray(roomData.quiz_data)) {
      parsedQuizData.questions = roomData.quiz_data
        .filter(q => q && typeof q === 'object') // Filter out invalid questions
        .map(q => {
          return {
            question: q.question || 'Missing question',
            options: Array.isArray(q.options) ? q.options : ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
            correct_answer: q.correct_answer || q.options?.[0] || 'Option 1'
          };
        });
    } 
    // If quiz_data is a string, try to parse it
    else if (typeof roomData.quiz_data === 'string') {
      try {
        let parsed;
        try {
          parsed = JSON.parse(roomData.quiz_data);
        } catch (e) {
          console.error('Failed to parse quiz_data string:', e);
          throw new Error('Invalid JSON in quiz_data');
        }
        
        // If parsed result is an array, treat it as questions array
        if (Array.isArray(parsed)) {
          parsedQuizData.questions = parsed
            .filter(q => q && typeof q === 'object')
            .map(q => ({
              question: q.question || 'Missing question',
              options: Array.isArray(q.options) ? q.options : ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
              correct_answer: q.correct_answer || q.options?.[0] || 'Option 1'
            }));
        } 
        // If parsed result is an object with questions property
        else if (parsed && typeof parsed === 'object' && Array.isArray(parsed.questions)) {
          parsedQuizData = {
            ...parsedQuizData,
            ...parsed,
            questions: parsed.questions
              .filter(q => q && typeof q === 'object')
              .map(q => ({
                question: q.question || 'Missing question',
                options: Array.isArray(q.options) ? q.options : ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
                correct_answer: q.correct_answer || q.options?.[0] || 'Option 1'
              }))
          };
        }
      } catch (error) {
        console.error('Error parsing quiz_data string:', error);
        // Keep default empty questions array
      }
    } 
    // If quiz_data is an object with questions array
    else if (roomData.quiz_data && typeof roomData.quiz_data === 'object' && Array.isArray(roomData.quiz_data.questions)) {
      parsedQuizData = {
        ...parsedQuizData,
        ...roomData.quiz_data,
        questions: roomData.quiz_data.questions
          .filter(q => q && typeof q === 'object')
          .map(q => ({
            question: q.question || 'Missing question',
            options: Array.isArray(q.options) ? q.options : ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
            correct_answer: q.correct_answer || q.options?.[0] || 'Option 1'
          }))
      };
    }
    
    // Ensure we have at least some default questions if we couldn't parse any
    if (!parsedQuizData.questions || parsedQuizData.questions.length === 0) {
      console.warn('No valid questions found, using fallback questions');
      parsedQuizData.questions = [
        {
          question: "Sample question 1",
          options: ["Option 1", "Option 2", "Option 3", "Option 4"],
          correct_answer: "Option 1"
        },
        {
          question: "Sample question 2",
          options: ["Option 1", "Option 2", "Option 3", "Option 4"],
          correct_answer: "Option 1"
        }
      ];
    }
    
    console.log('Transformed quiz data:', parsedQuizData);
    
    return {
      ...roomData,
      quiz_data: parsedQuizData
    };
  } catch (error) {
    console.error('Error transforming room data:', error);
    // Return a minimal valid room object with default questions
    return {
      ...roomData,
      quiz_data: {
        questions: [
          {
            question: "Fallback question 1",
            options: ["Option 1", "Option 2", "Option 3", "Option 4"],
            correct_answer: "Option 1"
          },
          {
            question: "Fallback question 2",
            options: ["Option 1", "Option 2", "Option 3", "Option 4"],
            correct_answer: "Option 1"
          }
        ],
        title: roomData.quiz_title || "Quiz",
        description: "This is a fallback quiz due to data loading issues.",
        timeLimit: 60
      }
    };
  }
};

/**
 * Creates fallback room data with default questions when transformation fails
 */
export const createFallbackRoom = (roomData: any) => {
  return {
    ...roomData,
    quiz_data: {
      questions: [
        {
          question: "Fallback question due to data error.",
          options: ["Option 1", "Option 2", "Option 3", "Option 4"],
          correct_answer: "Option 1"
        },
        {
          question: "Another fallback question.",
          options: ["Option 1", "Option 2", "Option 3", "Option 4"],
          correct_answer: "Option 1"
        }
      ],
      title: roomData.quiz_title || "Quiz",
      description: "Fallback quiz",
      timeLimit: 60
    }
  };
};
