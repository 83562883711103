
import { useEffect, useRef } from 'react';
import { useRoomData } from './use-room-data';
import { useRoomSubscription } from './use-room-subscription';
import { RealtimeChannel } from '@supabase/supabase-js';

export const useRoomState = (roomId: string | undefined) => {
  const channelRef = useRef<RealtimeChannel | null>(null);
  
  const {
    room,
    fetchRoomData,
    startGame,
  } = useRoomData(roomId);

  const {
    connectionStatus,
    subscribeToRoom: initSubscription,
  } = useRoomSubscription(roomId, async () => {
    await fetchRoomData();
  });

  // Set up auto-reconnect logic - making sure we don't create duplicate subscriptions
  useEffect(() => {
    let reconnectTimer: NodeJS.Timeout;

    if (connectionStatus === 'disconnected' && roomId) {
      reconnectTimer = setTimeout(() => {
        console.log('Attempting to reconnect...');
        if (!channelRef.current) {
          channelRef.current = initSubscription();
        }
      }, 5000); // Try to reconnect every 5 seconds
    }

    return () => {
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [connectionStatus, roomId, initSubscription]);

  // Function to subscribe to the room that ensures we only have one subscription
  const subscribeToRoom = () => {
    if (channelRef.current) {
      console.log('Cleaning up existing channel subscription');
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }
    
    console.log('Creating new room subscription');
    channelRef.current = initSubscription();
    return channelRef.current;
  };

  return {
    room,
    fetchRoomData,
    startGame,
    subscribeToRoom,
    connectionStatus,
  };
};
