
import { useState } from "react";
import { type Profile } from "../types";
import { UserTable } from "../user/UserTable";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { AdminMessageForm } from "./AdminMessageForm";
import { BroadcastMessageForm } from "./BroadcastMessageForm";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Megaphone } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

type AdminMessage = {
  id: string;
  content: string;
  subject: string;
  created_at: string;
  status: 'unread' | 'read' | 'archived';
  recipient_id: string;
  sender_id: string;
  recipient: {
    username: string | null;
    display_name: string | null;
  };
};

type BroadcastMessage = {
  id: string;
  content: string;
  subject: string;
  created_at: string;
  target_role: 'all' | 'admin' | 'ambassador' | 'user';
};

type MessagesData = {
  direct: AdminMessage[];
  broadcast: BroadcastMessage[];
};

export const MessagingTab = () => {
  const [selectedUser, setSelectedUser] = useState<Profile | null>(null);
  const [showBroadcast, setShowBroadcast] = useState(false);
  
  const { data: profiles = [] } = useQuery({
    queryKey: ['profiles'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    }
  });

  const handleMessageUser = (profile: Profile) => {
    setSelectedUser(profile);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Messaging Center</h2>
        <Button onClick={() => setShowBroadcast(true)}>
          <Megaphone className="w-4 h-4 mr-2" />
          Broadcast Message
        </Button>
      </div>

      <Tabs defaultValue="send" className="w-full">
        <TabsList>
          <TabsTrigger value="send">Send Messages</TabsTrigger>
          <TabsTrigger value="sent">Sent Messages</TabsTrigger>
        </TabsList>
        
        <TabsContent value="send">
          <div className="space-y-4">
            <p className="text-muted-foreground">
              Select a user from the list below to send them a direct message.
            </p>
            
            <div className="rounded-md border">
              <UserTable
                profiles={profiles}
                onMessageUser={handleMessageUser}
                showMessageAction
              />
            </div>
          </div>
        </TabsContent>

        <TabsContent value="sent">
          <SentMessagesView />
        </TabsContent>
      </Tabs>

      <Dialog open={!!selectedUser} onOpenChange={() => setSelectedUser(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send Direct Message</DialogTitle>
          </DialogHeader>
          {selectedUser && (
            <AdminMessageForm
              recipient={selectedUser}
              onClose={() => setSelectedUser(null)}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={showBroadcast} onOpenChange={setShowBroadcast}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Broadcast Message</DialogTitle>
          </DialogHeader>
          <BroadcastMessageForm onClose={() => setShowBroadcast(false)} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SentMessagesView = () => {
  const { data: messages = { direct: [], broadcast: [] } } = useQuery<MessagesData>({
    queryKey: ['sent-messages'],
    queryFn: async () => {
      const [adminMessages, broadcastMessages] = await Promise.all([
        supabase
          .from('admin_messages')
          .select(`
            id,
            content,
            subject,
            created_at,
            status,
            recipient_id,
            sender_id,
            recipient:profiles!recipient_id (
              username,
              display_name
            )
          `)
          .order('created_at', { ascending: false }),
        supabase
          .from('broadcast_messages')
          .select('*')
          .order('created_at', { ascending: false })
      ]);

      if (adminMessages.error) throw adminMessages.error;
      if (broadcastMessages.error) throw broadcastMessages.error;

      return {
        direct: adminMessages.data as AdminMessage[],
        broadcast: broadcastMessages.data as BroadcastMessage[]
      };
    }
  });

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mb-4">Broadcast Messages</h3>
        <div className="space-y-4">
          {messages.broadcast?.map((message) => (
            <div key={message.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h4 className="font-medium">{message.subject}</h4>
                  <p className="text-sm text-muted-foreground">
                    Target: {message.target_role}
                  </p>
                </div>
                <span className="text-sm text-muted-foreground">
                  {formatDate(message.created_at)}
                </span>
              </div>
              <p className="text-sm">{message.content}</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Direct Messages</h3>
        <div className="space-y-4">
          {messages.direct?.map((message) => (
            <div key={message.id} className="p-4 border rounded-lg">
              <div className="flex justify-between items-start mb-2">
                <div>
                  <h4 className="font-medium">{message.subject}</h4>
                  <p className="text-sm text-muted-foreground">
                    To: {message.recipient?.display_name || message.recipient?.username}
                  </p>
                </div>
                <span className="text-sm text-muted-foreground">
                  {formatDate(message.created_at)}
                </span>
              </div>
              <p className="text-sm">{message.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
