
import { supabase } from '@/integrations/supabase/client';
import { MultiplayerRoom, MultiplayerQuizData } from '@/types/multiplayer-room';

export const fetchActiveRooms = async () => {
  const oneHourAgo = new Date();
  oneHourAgo.setHours(oneHourAgo.getHours() - 1);

  const { data, error } = await supabase
    .from('multiplayer_rooms')
    .select(`
      *,
      multiplayer_participants (
        user_id,
        is_ready,
        profiles:user_id (
          display_name
        )
      )
    `)
    .or(`status.eq.waiting,and(status.eq.in_progress,started_at.gt.${oneHourAgo.toISOString()})`)
    .order('created_at', { ascending: false });
  
  if (error) throw error;
  
  const activeRooms = data.filter(room => 
    room.multiplayer_participants && 
    room.multiplayer_participants.length > 0
  );
  
  const transformedData = activeRooms.map(room => ({
    ...room,
    quiz_data: typeof room.quiz_data === 'string' 
      ? JSON.parse(room.quiz_data)
      : room.quiz_data
  })) as MultiplayerRoom[];
  
  return transformedData;
};

