
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { MultiplayerTemplateForm } from "./MultiplayerTemplateForm";
import { MultiplayerQuizTemplate } from "@/types/multiplayer";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Question } from "@/types/quiz";

interface MultiplayerQuizEditFormProps {
  quiz: MultiplayerQuizTemplate;
  onBack: () => void;
  onSuccess: () => void;
}

export const MultiplayerQuizEditForm = ({ quiz, onBack, onSuccess }: MultiplayerQuizEditFormProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState(quiz.title);
  const [description, setDescription] = useState(quiz.description || '');
  const [timeLimit, setTimeLimit] = useState(quiz.time_limit?.toString() || "30");
  const [level, setLevel] = useState(quiz.level);
  const [levelType, setLevelType] = useState(quiz.level_type);
  const [questions, setQuestions] = useState<Question[]>(
    quiz.questions.map(q => ({
      id: crypto.randomUUID(),
      quiz_id: quiz.id,
      level: quiz.level,
      question: q.question,
      options: q.options,
      correct_answer: q.correct_answer
    }))
  );

  const handleQuestionsImported = (importedQuestions: Question[]) => {
    setQuestions(prevQuestions => [...prevQuestions, ...importedQuestions]);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (questions.length === 0) {
      toast({
        title: "Error",
        description: "Please add at least one question to the quiz",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const { error } = await supabase
        .from("multiplayer_quiz_templates")
        .update({
          title,
          description,
          time_limit: parseInt(timeLimit),
          level,
          level_type: levelType,
          questions: questions.map(q => ({
            question: q.question,
            correct_answer: q.correct_answer,
            options: Array.isArray(q.options) ? q.options : []
          })),
        })
        .eq('id', quiz.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Template updated successfully",
      });
      onSuccess();
    } catch (error) {
      console.error("Error updating template:", error);
      toast({
        title: "Error",
        description: "Failed to update template",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <Button 
          onClick={onBack}
          variant="outline"
        >
          Back to Template List
        </Button>
      </div>
      <MultiplayerTemplateForm 
        title={title}
        description={description}
        timeLimit={timeLimit}
        level={level}
        levelType={levelType}
        questions={questions}
        isSubmitting={isSubmitting}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onTimeLimitChange={setTimeLimit}
        onLevelChange={setLevel}
        onLevelTypeChange={setLevelType}
        onQuestionsImported={handleQuestionsImported}
        onSubmit={handleSubmit}
        onBack={onBack}
      />
    </div>
  );
};
