
import React from 'react';
import { HomeLink } from '@/components/HomeLink';
import { Button } from '@/components/ui/button';

interface MultiplayerGameOverProps {
  participants: any[];
  onGameEnd: () => void;
}

export const MultiplayerGameOver = ({
  participants,
  onGameEnd
}: MultiplayerGameOverProps) => {
  return (
    <div className="container mx-auto px-4 py-8">
      <HomeLink />
      <h2 className="text-2xl font-bold text-gameGold mb-8">Game Over!</h2>
      <div className="bg-gamePurple/30 p-6 rounded-lg">
        <h3 className="text-xl text-gameGold mb-4">Final Scores:</h3>
        {participants.map((participant) => (
          <div key={participant.user_id} className="mb-2">
            <span className="text-gameGold">{participant.user_id}: </span>
            <span className="text-white">{participant.score} points</span>
          </div>
        ))}
        <Button onClick={onGameEnd} className="mt-4">
          Back to Lobby
        </Button>
      </div>
    </div>
  );
};
