
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Megaphone } from "lucide-react";

interface BroadcastMessageFormProps {
  onClose: () => void;
}

export const BroadcastMessageForm = ({ onClose }: BroadcastMessageFormProps) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [targetRole, setTargetRole] = useState<"all" | "admin" | "ambassador" | "user">("all");
  const [isSending, setIsSending] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("Not authenticated");

      const { error } = await supabase
        .from('broadcast_messages')
        .insert({
          sender_id: user.id,
          target_role: targetRole,
          subject,
          content
        });

      if (error) throw error;

      toast({
        title: "Message Broadcast",
        description: `Message broadcast to ${targetRole} users`,
      });
      
      onClose();
    } catch (error) {
      console.error('Error broadcasting message:', error);
      toast({
        title: "Error",
        description: "Failed to broadcast message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Select
          value={targetRole}
          onValueChange={(value: "all" | "admin" | "ambassador" | "user") => setTargetRole(value)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select target role" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Users</SelectItem>
            <SelectItem value="admin">Admins Only</SelectItem>
            <SelectItem value="ambassador">Ambassadors Only</SelectItem>
            <SelectItem value="user">Regular Users Only</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-2">
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <div className="space-y-2">
        <Textarea
          placeholder="Message content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
          rows={5}
        />
      </div>
      <div className="flex justify-end gap-2">
        <Button type="button" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSending}>
          <Megaphone className="w-4 h-4 mr-2" />
          {isSending ? "Broadcasting..." : "Broadcast Message"}
        </Button>
      </div>
    </form>
  );
};
