
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Quiz } from "@/types/quiz";
import { useEffect } from "react";
import { useToast } from "@/hooks/use-toast";

export interface QuizResponse {
  id: string;
  score: number;
  perfect_score: boolean;
}

export const useQuizResponse = (quiz: Quiz) => {
  const { toast } = useToast();

  const { data: quizResponse } = useQuery({
    queryKey: ['quizResponse', quiz.id],
    queryFn: async () => {
      if (!quiz.id) return null;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data: response, error } = await supabase
        .from('quiz_responses')
        .select('*, questions:quizzes(questions(*))')
        .eq('quiz_id', quiz.id)
        .eq('user_id', user.id)
        .maybeSingle();

      if (error) {
        console.error('Error fetching quiz response:', error);
        return null;
      }

      if (!response) return null;
      
      return {
        ...response,
        isPerfectScore: response.perfect_score
      };
    },
    enabled: !!quiz.id,
  });

  // Check for newly unlocked levels when quiz response changes
  useEffect(() => {
    const checkNewlyUnlockedLevel = async () => {
      if (quizResponse?.perfect_score) {
        // Get the most recently updated level
        const { data: unlockedLevel } = await supabase
          .from('levels')
          .select('title, order_number, updated_at')
          .eq('is_locked', false)
          .order('updated_at', { ascending: false })
          .limit(1)
          .single();

        if (unlockedLevel) {
          const unlockTime = new Date(unlockedLevel.updated_at).getTime();
          const now = new Date().getTime();
          const isRecentlyUnlocked = (now - unlockTime) < 5000; // 5 seconds

          if (isRecentlyUnlocked) {
            toast({
              title: "🎉 New Level Unlocked!",
              description: `You've unlocked Level ${unlockedLevel.order_number}: ${unlockedLevel.title}`,
              variant: "default"
            });
          }
        }
      }
    };

    checkNewlyUnlockedLevel();
  }, [quizResponse?.perfect_score, toast]);

  return {
    isCompleted: !!quizResponse?.perfect_score, // Quiz is only completed with perfect score
    isPerfectScore: quizResponse?.perfect_score || false,
    score: quizResponse?.score || 0,
    hasAttempted: !!quizResponse
  };
};
