
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";

interface AmbassadorStats {
  id: string;
  display_name: string;
  username: string | null;
  is_ambassador: boolean;
  referral_code: string;
  referral_count: number;
}

interface AmbassadorTableProps {
  ambassadors: AmbassadorStats[];
}

export const AmbassadorTable = ({ ambassadors }: AmbassadorTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>User</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Referral Code</TableHead>
          <TableHead>Referral Count</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {ambassadors.map((ambassador) => (
          <TableRow key={ambassador.id}>
            <TableCell>
              <div className="flex flex-col">
                <span className="font-medium">{ambassador.display_name}</span>
                {ambassador.username && (
                  <span className="text-sm text-muted-foreground">@{ambassador.username}</span>
                )}
              </div>
            </TableCell>
            <TableCell>
              {ambassador.is_ambassador ? (
                <Badge className="bg-gameGold text-gamePurple">Ambassador</Badge>
              ) : (
                <Badge variant="secondary">Regular User</Badge>
              )}
            </TableCell>
            <TableCell>{ambassador.referral_code}</TableCell>
            <TableCell>{ambassador.referral_count}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
