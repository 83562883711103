
import { Users } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { AmbassadorBadge } from "./referral/AmbassadorBadge";
import { BecomeAmbassadorButton } from "./referral/BecomeAmbassadorButton";
import { ReferralLink } from "./referral/ReferralLink";
import { useAmbassadorStats } from "./referral/useAmbassadorStats";

interface ReferralCardProps {
  referralCode: string;
  isAmbassador: boolean;
}

export const ReferralCard = ({ referralCode, isAmbassador }: ReferralCardProps) => {
  const { referralCount, ambassadorRank, isLoading, error } = useAmbassadorStats();

  // Enhanced debugging for visibility issues
  console.log('ReferralCard - Detailed Debug:', { 
    props: { referralCode, isAmbassador },
    stats: { referralCount, ambassadorRank, isLoading, error },
    shouldShowBadgeCheck: {
      referralCountExists: Boolean(referralCount),
      referralCountValue: referralCount,
      isGreaterOrEqualToOne: referralCount >= 1,
      finalCheck: Boolean(referralCount) && referralCount >= 1
    }
  });

  if (isLoading) {
    return (
      <Card className="bg-white/10 backdrop-blur-md">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium text-gameGold">Referrals</CardTitle>
          <Users className="h-4 w-4 text-gameGold" />
        </CardHeader>
        <CardContent>
          <div className="animate-pulse space-y-2">
            <div className="h-8 bg-white/20 rounded" />
            <div className="h-4 bg-white/20 rounded w-3/4" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    console.error('Error in ReferralCard:', error);
    return null;
  }

  // Simplified condition to check for referrals >= 1
  const shouldShowBadge = typeof referralCount === 'number' && referralCount >= 1;
  // Show button only if user has exactly 0 referrals
  const shouldShowButton = referralCount === 0;

  return (
    <Card className="bg-white/10 backdrop-blur-md">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium text-gameGold">Referrals</CardTitle>
        <Users className="h-4 w-4 text-gameGold" />
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          {shouldShowBadge && (
            <AmbassadorBadge rank={ambassadorRank || `Ambassador Rank ${referralCount}`} />
          )}
          
          {shouldShowButton && (
            <BecomeAmbassadorButton referralCount={referralCount} />
          )}
          
          <div className="text-xl font-bold text-white mb-2">
            {referralCount || 0} {(referralCount || 0) === 1 ? 'Referral' : 'Referrals'}
          </div>

          <ReferralLink referralCode={referralCode} />

          <CardDescription className="text-gray-300">
            {!shouldShowBadge 
              ? "Invite friends to earn ambassador status"
              : "Share your link to earn more rewards"}
          </CardDescription>
        </div>
      </CardContent>
    </Card>
  );
};
