
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { AdminHeader } from "@/components/admin/AdminHeader";
import { AdminContent } from "@/components/admin/content/AdminContent";
import { AdminLoading } from "@/components/admin/loading/AdminLoading";

const Admin = () => {
  const navigate = useNavigate();
  const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'users' | 'quizzes' | 'levels' | 'multiplayer' | 'ads' | 'ambassadors' | 'messages' | 'ratings'>('users');
  const { toast } = useToast();

  useEffect(() => {
    checkAdminStatus();
  }, []);

  const checkAdminStatus = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/auth');
        return;
      }

      const { data: profile, error } = await supabase
        .from('profiles')
        .select('is_admin')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error("Error fetching profile:", error);
        navigate('/');
        toast({
          title: "Error",
          description: "Failed to check admin status",
          variant: "destructive",
        });
        return;
      }

      if (!profile?.is_admin) {
        navigate('/');
        toast({
          title: "Access Denied",
          description: "You don't have admin privileges",
          variant: "destructive",
        });
        return;
      }

      setCurrentUserIsAdmin(true);
    } catch (error) {
      console.error("Error in admin check:", error);
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Error signing out:", error);
        toast({
          title: "Error",
          description: "Failed to sign out. Please try again.",
          variant: "destructive",
        });
        return;
      }
      
      navigate('/auth');
      toast({
        title: "Success",
        description: "Signed out successfully",
      });
    } catch (error) {
      console.error("Error in logout process:", error);
      navigate('/auth');
    }
  };

  if (isLoading) {
    return <AdminLoading />;
  }

  if (!currentUserIsAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen p-8">
      <AdminHeader 
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onLogout={handleLogout}
      />
      <div className="mt-8">
        <AdminContent activeTab={activeTab} />
      </div>
    </div>
  );
};

export default Admin;
