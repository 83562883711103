
import { useState } from "react";
import { TableCell, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Mail, Globe, Trophy, Calendar, User, ChevronDown, ChevronUp } from "lucide-react";
import { type Profile } from "../types";
import { UserActions } from "./UserActions";

interface UserTableRowProps {
  profile: Profile;
  onToggleAdmin: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onToggleRestriction: (profileId: string, currentStatus: boolean | null) => Promise<void>;
  onDeleteUser: (profileId: string) => Promise<void>;
  onMessageUser?: (profile: Profile) => void;
  showMessageAction?: boolean;
}

export const UserTableRow = ({ 
  profile, 
  onToggleAdmin, 
  onToggleRestriction, 
  onDeleteUser,
  onMessageUser,
  showMessageAction = false
}: UserTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <>
      <TableRow 
        className={`cursor-pointer ${profile.is_restricted ? "opacity-60" : ""}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <TableCell>
          <div className="flex items-center justify-between">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center gap-2">
                <span className="font-medium">{profile.username || 'No username'}</span>
                {isExpanded ? (
                  <ChevronUp className="h-4 w-4 text-muted-foreground" />
                ) : (
                  <ChevronDown className="h-4 w-4 text-muted-foreground" />
                )}
              </div>
              <div className="flex gap-2">
                <Badge variant={profile.is_admin ? "default" : "secondary"}>
                  {profile.is_admin ? 'Admin' : 'User'}
                </Badge>
                {profile.is_restricted && (
                  <Badge variant="destructive">Restricted</Badge>
                )}
              </div>
            </div>
            <UserActions 
              profile={profile}
              onToggleAdmin={onToggleAdmin}
              onToggleRestriction={onToggleRestriction}
              onDeleteUser={onDeleteUser}
              onMessageUser={onMessageUser}
              showMessageAction={showMessageAction}
            />
          </div>
        </TableCell>
      </TableRow>

      {isExpanded && (
        <TableRow className={profile.is_restricted ? "opacity-60" : ""}>
          <TableCell>
            <div className="pl-4 space-y-4 py-2">
              <div className="flex flex-col space-y-2">
                <div className="flex items-center text-sm text-muted-foreground">
                  <Mail className="h-4 w-4 mr-1" />
                  <span>{profile.id}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <User className="h-4 w-4" />
                  <span>
                    {profile.first_name || profile.last_name
                      ? `${profile.first_name || ''} ${profile.last_name || ''}`
                      : 'Not specified'}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Globe className="h-4 w-4" />
                  <span>{profile.country || 'Not specified'}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Trophy className="h-4 w-4" />
                  <span>{profile.points} points</span>
                </div>
                <div className="flex flex-col space-y-1">
                  <div className="flex items-center text-sm">
                    <Calendar className="h-4 w-4 mr-1" />
                    <span>Created: {formatDate(profile.created_at)}</span>
                  </div>
                  <div className="text-sm text-muted-foreground">
                    Updated: {formatDate(profile.updated_at)}
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

