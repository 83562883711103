
import React from 'react';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';

interface MultiplayerRoomCardProps {
  room: any;
  currentUser: string | null;
  onJoin: (roomId: string) => void;
  onLeave: (roomId: string) => void;
  onReenter: (roomId: string) => void;
  maxParticipants: number;
  isLoading?: boolean;
}

export const MultiplayerRoomCard = ({
  room,
  currentUser,
  onJoin,
  onLeave,
  onReenter,
  maxParticipants,
  isLoading = false,
}: MultiplayerRoomCardProps) => {
  const canJoinRoom = () => {
    const isParticipant = room.multiplayer_participants.some(
      (p: any) => p.user_id === currentUser
    );
    return room.multiplayer_participants.length < maxParticipants && !isParticipant;
  };

  const isParticipant = () => {
    return room.multiplayer_participants.some(
      (p: any) => p.user_id === currentUser
    );
  };

  return (
    <div className="bg-gamePurple/30 p-4 rounded-lg flex justify-between items-center">
      <div>
        {room.quiz_title && (
          <h3 className="text-xl text-gameGold mb-2">{room.quiz_title}</h3>
        )}
        <p className="text-gameGold">
          Warriors: {room.multiplayer_participants.length}/{maxParticipants}
        </p>
        <div className="text-sm text-gray-400">
          {room.multiplayer_participants.map((participant: any) => (
            <span key={participant.user_id} className="mr-2">
              {participant.profiles.display_name}
            </span>
          ))}
        </div>
        <p className="text-sm text-gray-400">
          Created: {new Date(room.created_at).toLocaleString()}
        </p>
      </div>
      {canJoinRoom() && (
        <Button 
          onClick={() => onJoin(room.id)}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Joining...
            </>
          ) : (
            'Join Battle'
          )}
        </Button>
      )}
      {isParticipant() && (
        <div className="flex gap-2">
          <Button 
            onClick={() => onReenter(room.id)}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Loading...
              </>
            ) : (
              'Re-enter Battle'
            )}
          </Button>
          <Button 
            variant="destructive"
            onClick={() => onLeave(room.id)}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Leaving...
              </>
            ) : (
              'Leave Battle'
            )}
          </Button>
        </div>
      )}
    </div>
  );
};
